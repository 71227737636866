import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {UserState} from "./core/store/user/user.reducer";
import {Observable} from "rxjs";
import {authenticateSuccess} from "./core/store/user/user.actions";
import {isUserAuthenticated} from "./core/store/user/user.selector";
import {AppState} from "./core/store/app.reducer";
import {ActivatedRoute} from "@angular/router";
import {setDomainData} from "./core/store/domain/domain.actions";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'link-insider';

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {
    const user = JSON.parse(<string>localStorage.getItem('user'));
    const token = localStorage.getItem('token');
    if (token && user) {
      this.store.dispatch(authenticateSuccess({user,token}))
    }
    const domainData = JSON.parse(<string>localStorage.getItem('last_requested_domain_data'));
    if (domainData) {
      this.store.dispatch(setDomainData(domainData));
    }
  }
  
  ngOnInit() {
    this.route.queryParams.subscribe(param => {
      if (param.domain) {
        const typeId = param.type ? Number(param.type) : 0
        this.store.dispatch(setDomainData({domain: param.domain, domainTypeId: typeId}))
      }
    })
    
    this.initIntercom();
  }
  
  initIntercom() {
    (window as any).Intercom('boot', {
      app_id: environment.intercomAppId, domain: environment.appUrl, vertical_padding: 100, hide_default_launcher: false
    });
  }
}
