import { createSelector } from '@ngrx/store';
import {AppState} from "../app.reducer";
import {UserState} from "./user.reducer";

export const selectUser = (state: AppState) => state.user;

export const getUserData = createSelector(
  selectUser,
  (state: UserState) => state.user
)

export const isUserAuthenticated = createSelector(
  selectUser,
  (state: UserState) => state.authenticated
)

export const isLoading = createSelector(
	selectUser,
	(state: UserState) => state.loading
)

export const hasUserTariff = createSelector(
	selectUser,
	(state: UserState) => !!state.user?.tariff_id && (state.user?.tariff_id !== 1 || state.requested_demo)
)

export const isDemo = createSelector(
	selectUser,
	(state: UserState) => state.requested_demo
)

export const hasUserPaidTariff = createSelector(
	selectUser,
	(state: UserState) => !!state.user?.tariff_id && state.user?.tariff_id > 2
)

export const getCurrentTariff = createSelector(
	selectUser,
	(state: UserState) => {
		return (state.user) ? {tariff_id: state.user.tariff_id, tariff_title: state.user.tariff_title, tariff_description: state.user.tariff_description} : null;
	}
)

// export const getAffiliateLink = createSelector(
// 	selectUser,
// 	(state: UserState) => state.user?.affiliate_link
// )

// export const isUserVerified = createSelector(
//   selectUser,
//   (state: UserState) => !!state.user?.verified
// )
