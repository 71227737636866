import {Component, EventEmitter, Input, OnInit, Output, ɵmarkDirty as markDirty} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {
  fadeInLeftOnEnterAnimation,
} from "angular-animations";
import {Store} from "@ngrx/store";
import {AppState} from "../../store/app.reducer";
import {Observable} from "rxjs";
import {BalanceState} from "../../store/balance/balance.reducer";
import {balanceState} from "../../store/balance/balance.selectors";
import {TariffData} from "../../models/tariff-data.model";
import {getCurrentTariff, isDemo} from "../../store/user/user.selector";

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
  animations: [
    fadeInLeftOnEnterAnimation({anchor: 'enter', duration: 500})
  ]
})
export class LeftMenuComponent implements OnInit {
  
  /**
   * Check if left menu is shown for user
   * @type {boolean}
   */
  @Input() isShown!: boolean;
  
  public balanceData$: Observable<BalanceState> | null;
  
  isDemo$: Observable<boolean>;
  
  tariffData$: Observable<TariffData | null>;
  
  /**
   * Emmit event to toggle left menu
   * @type {EventEmitter<void>}
   */
  @Output() menuToggle: EventEmitter<void> = new EventEmitter<void>();

  constructor(public router: Router, private store: Store<AppState>) {
    this.router.events
      .subscribe(val => {
        if (val instanceof NavigationEnd)
          markDirty(this);
    })
    this.balanceData$ = store.select(balanceState);
    this.tariffData$ = store.select(getCurrentTariff)
    this.isDemo$ = store.select(isDemo)
  }

  ngOnInit(): void {
  }
  
  /**
   * Emmit event to toggle left menu on module link click
   * @return {void}
   */
  menuItemClickHandler(): void {
    if (window.innerWidth < 1200)
      this.menuToggle.emit();
  }

}
