import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from "./auth/guards/auth.guard";
import {VerifyGuard} from "./auth/guards/verify.guard";
import {AuthComponent, SignInComponent} from "./auth/containers";
import {OnlyGuestGuard} from "./auth/guards/only-guest.guard";
import {DashboardLayoutComponent} from "./core/layouts/dashboard-layout/dashboard-layout.component";
import {PlugLayoutComponent} from "./core/layouts/plug-layout/plug-layout.component";
import {CurrentDomainGuard} from "./auth/guards/current-domain.guard";

const routes: Routes = [{
  path: '',
  component: DashboardLayoutComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: '',
      redirectTo: 'overview',
      pathMatch: 'full'
    },
    {
      path: 'overview',
      canActivate: [VerifyGuard,CurrentDomainGuard],
      loadChildren:() => import('./products/overview/overview.module').then(m => m.OverviewModule)
    }, {
      path: 'backlinks',
      canActivate: [VerifyGuard,CurrentDomainGuard],
      loadChildren: () => import('./products/backlinks/backlinks.module').then(m => m.BacklinksModule)
    }, {
      path: 'top-pages',
      canActivate: [VerifyGuard,CurrentDomainGuard],
      loadChildren: () => import('./products/top-pages/top-pages.module').then(m => m.TopPagesModule)
    }, {
      path: 'referring-domains',
      canActivate: [VerifyGuard,CurrentDomainGuard],
      loadChildren: () => import('./products/referring-domains/referring-domains.module').then(m => m.ReferringDomainsModule)
    }, {
      path: 'anchors',
      canActivate: [VerifyGuard,CurrentDomainGuard],
      loadChildren: () => import('./products/anchors/anchors.module').then(m => m.AnchorsModule)
    }, {
      path: 'link-gap',
      canActivate: [VerifyGuard,CurrentDomainGuard],
      loadChildren: () => import('./products/link-gap/link-gap.module').then(m => m.LinkGapModule)
    }, {
      path: 'referring-networks',
      canActivate: [VerifyGuard,CurrentDomainGuard],
      loadChildren: () => import('./products/referring-networks/referring-networks.module').then(m => m.ReferringNetworksModule)
    }, {
      path: 'link-opportunities',
      canActivate: [VerifyGuard,CurrentDomainGuard],
      loadChildren: () => import('./products/link-opportunities/link-opportunities.module').then(m => m.LinkOpportunitiesModule)
    }, {
      path: 'plans',
      canActivate: [VerifyGuard],
      loadChildren: () => import('./products/plans/tariffs.module').then(m => m.TariffsModule)
    }, {
      path: 'link-competitors',
      canActivate: [VerifyGuard,CurrentDomainGuard],
      loadChildren: () => import('./products/competitors/competitors.module').then(m => m.CompetitorsModule)
    }, {
      path: 'domains',
      canActivate: [VerifyGuard],
      loadChildren: () => import('./core/modules/domain-core/domain-core.module').then(m => m.DomainCoreModule)
    }, {
      path: 'socialite_provider/google/callback',
      loadChildren: () => import('./products/plans/tariffs.module').then(m => m.TariffsModule)
    },
    {
      path: 'user',
      loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
    },
  ]
}, {
  path: '',
  component: PlugLayoutComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: '',
      loadChildren: () => import('./products/plans/tariffs.module').then(m => m.TariffsModule)
    }
  ]
}, {
  path: '',
  component: AuthComponent,
  canActivate: [OnlyGuestGuard],
  children: [
    {
      path: '',
      loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    }
  ]
}, {
  path: '**',
  redirectTo: '',
  pathMatch: 'full'
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
