import {Action, createReducer, on} from "@ngrx/store";
import {updateBalanceData, setBalanceData, initBalanceData, setErrorBalanceData} from "./balance.actions";

export interface IBalanceError {
  domains_reached: boolean;
  rows_per_month_reached: boolean;
  rows_per_report_reached: boolean;
}

export interface BalanceState {
    domains_limit: number;
    domains_used: number;
    rows_per_report_limit: number;
    rows_per_month_limit: number;
    rows_per_month_used: number;
    errors: Partial<IBalanceError> | null
}

const initialState: BalanceState = {
    domains_limit: 0,
    domains_used: 0,
    rows_per_report_limit: 0,
    rows_per_month_limit: 0,
    rows_per_month_used: 0,
    errors: null
}

const _balanceReducer = createReducer(
  initialState,
  on(updateBalanceData, (state, balanceData: {domains_limit: number | string | null, domains_used: number | string | null, rows_per_report_limit: number | string | null, rows_per_month_limit: number | string | null, rows_per_month_used: number | string | null}) => {
      const updatedBalanceData = {
          domains_limit: balanceData.domains_limit !== null ? +balanceData.domains_limit : state.domains_limit,
          domains_used: balanceData.domains_used !== null && balanceData.domains_used >= state.domains_used ? +balanceData.domains_used : state.domains_used,
          rows_per_report_limit: balanceData.rows_per_report_limit !== null ? +balanceData.rows_per_report_limit : state.rows_per_report_limit,
          rows_per_month_limit: balanceData.rows_per_month_limit !== null ? +balanceData.rows_per_month_limit : state.rows_per_month_limit,
          rows_per_month_used: balanceData.rows_per_month_used !== null && balanceData.rows_per_month_used >= state.rows_per_month_used ? +balanceData.rows_per_month_used : state.rows_per_month_used,
          errors: null
      }
      return state = updatedBalanceData;
  }),
  on(initBalanceData, state => state = {...state}),
  on(setBalanceData, (state, balanceData: {domains_limit: number, domains_used: number, rows_per_report_limit: number, rows_per_month_limit: number, rows_per_month_used: number}) => state = {...balanceData, errors: null}),
  on(setErrorBalanceData, (state, {errors}) => state = {...state, errors})
);

export function balanceReducer(state: BalanceState | undefined, action: Action) {
  return _balanceReducer(state, action)
}
