import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ProfileComponent} from "./containers/profile/profile.component";
import {GoogleConnectionComponent} from "./containers/google-connection/google-connection.component";
import {BillingComponent} from "./containers/billing/billing.component";

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'ga-connection',
    component: GoogleConnectionComponent
  },
  {
    path: 'payments',
    component: BillingComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
