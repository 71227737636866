<div class="container-fluid">
	<div class="card mb-5 mb-xl-10">
		<div class="card-header border-0 cursor-pointer" role="button" aria-expanded="true">
			<!--begin::Card title-->
			<div class="card-title m-0">
				<h3 class="fw-bold m-0">Payments List</h3>
			</div>
			<!--end::Card title-->
		</div>
		<div class="card-body border-top p-9">
			<div *ngIf="(paymentList$ | async) as paymentList">
				<dx-data-grid
					[dataSource]="paymentList"
				>
					<dxi-column
						dataField="date"
						dataType="datetime"
						[format]="currentDateFormat + ' hh:mm:ss aa'">
					</dxi-column>
					
					<dxi-column
						dataField="customer_email"
						caption="Email"
						dataType="string">
					</dxi-column>
					
					<dxi-column
						dataField="billed_total"
						dataType="number"
						[width]="200"
						cellTemplate="billedTotal_cell-template">
						<div *dxTemplate="let data of 'billedTotal_cell-template'">
							<div class="text-success fw-bold">{{data.value | currency}}</div>
						</div>
					</dxi-column>
					
					<dxi-column
						dataField="invoice"
						dataType="string"
						[width]="200"
						cellTemplate="invoice_cell-template">
						<div *dxTemplate="let data of 'invoice_cell-template'">
							<a [attr.href]="data.value" target="_blank" class="btn-sm btn btn-light btn-text-primary btn-hover-text-primary font-weight-bold">View</a>
						</div>
					</dxi-column>
				
				</dx-data-grid>
			</div>
		</div>
	</div>
</div>
