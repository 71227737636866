import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {AppState} from "../../core/store/app.reducer";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment
} from "@angular/router";
import {Observable} from "rxjs";
import {hasUserTariff} from "../../core/store/user/user.selector";
import {tap} from "rxjs/operators";

@Injectable()
export class VerifyGuard implements CanActivate, CanLoad {
  constructor (private store: Store<AppState>,
                private router: Router) {}
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.store.select(hasUserTariff)
      .pipe(tap(hasUserTariff => {
        if (!hasUserTariff) {
          this.router.navigate(['select-plans'])
        }
      }));
  }
  
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | boolean {
    return this.store.select(hasUserTariff)
      .pipe(tap(hasUserTariff => {
        if (!hasUserTariff) {
          this.router.navigate(['select-plans']);
        }
      }))
  }
}
