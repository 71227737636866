<ul *ngIf="isUserAuthenticated; else loginBlock" [@fadeInOnEnter] id="user-menu" class="nk-quick-nav">
  <li class="dropdown user-dropdown show">
    <a class="dropdown-toggle mr-n1" data-toggle="dropdown" aria-expanded="true">
      <div class="user-toggle">
        <div class="user-avatar sm"><span>{{userData?.name?.charAt(0)?.toUpperCase()}}</span></div>
        <div class="user-info d-xl-block">
          <div class="user-name dropdown-indicator unselectable">{{userData?.name}}</div>
        </div>
      </div>
    </a>
    <div *ngIf="isMenuVisible" [class.show]="isMenuVisible" [@fadeInOnEnter] [@fadeOutOnLeave] class="dropdown-menu dropdown-menu-md dropdown-menu-right" x-placement="bottom-end" style="position: absolute; will-change: transform; top: 0px; right: 0px; transform: translate3d(20px, 43px, 0px);">
      <div class="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
        <div id="user-card" class="user-card">
          <div class="user-avatar"><span>{{userData?.name?.charAt(0)?.toUpperCase()}}</span></div>
          <div class="user-info"><span class="lead-text">{{userData?.name}}</span><span class="sub-text">{{userData?.email}}</span></div>
        </div>
      </div>
      <div class="dropdown-inner">
        <ul class="link-list">
          <li>
            <a routerLink="user/profile">
              <span>Profile</span>
            </a>
          </li>
          <li *ngIf="!isDemo">
            <a routerLink="plans">
              <span>Plans & Subscriptions</span>
            </a>
          </li>
          <li *ngIf="!isDemo">
            <a routerLink="user/payments">
              <span>Payments</span>
            </a>
          </li>
          <li *ngIf="!isDemo">
            <a routerLink="user/ga-connection">
              <span>GA/GSC connection</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="dropdown-inner">
        <ul class="link-list">
          <li>
            <a class="link" (click)="logOutHandler()">
              <span>Log out</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </li>
</ul>

<ng-template #loginBlock>
  <button mat-button [@fadeInOnEnter] id="login-btn" (click)="showGoogleBtn()">Login</button>
</ng-template>

<div *ngIf="isGoogleBtnVisible"
     [@rotateInUpRightOnEnter]
     [@rotateOutDownRightOnLeave]
     (click)="loginHandler()"
     id="google-btn" class="google-btn">
  <div class="google-icon-wrapper">
    <img class="google-icon-svg" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
  </div>
  <p class="btn-text"><b>Sign up with Google</b></p>
</div>
