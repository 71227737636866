 <!--begin::Content-->
    <div class="w-md-400px">
      <!--begin::Form-->
      <form class="form w-100" novalidate="novalidate" id="kt_sign_in_form" [formGroup]="form">
        <!--begin::Heading-->
        <div class="text-center mb-11">
          <!--begin::Title-->
          <h1 class="text-dark fw-bolder mb-3">Sign In</h1>
          <!--end::Title-->
          <!--begin::Subtitle-->
          <div class="text-gray-500 fw-semibold fs-6">To your account</div>
          <!--end::Subtitle=-->
        </div>
        <!--begin::Heading-->
        <!--begin::Login options-->
<!--        <div class="row g-3 mb-9 justify-content-center">-->
          <!--begin::Col-->
<!--          <div class="col-md-6">-->
            <!--begin::Google link=-->
<!--            <button (click)="goToThirdParty('google')" class="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">-->
<!--              <img alt="Logo" src="assets/images/brand-logos/google-icon.svg" class="h-15px me-3" />Sign in with Google</button>-->
            <!--end::Google link=-->
<!--          </div>-->
          <!--end::Col-->
<!--          &lt;!&ndash;begin::Col&ndash;&gt;-->
<!--          <div class="col-md-6">-->
<!--            &lt;!&ndash;begin::Google link=&ndash;&gt;-->
<!--            <a href="#" class="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">-->
<!--              <img alt="Logo" src="assets/media/svg/brand-logos/apple-black.svg" class="theme-light-show h-15px me-3" />-->
<!--              <img alt="Logo" src="assets/media/svg/brand-logos/apple-black-dark.svg" class="theme-dark-show h-15px me-3" />Sign in with Apple</a>-->
<!--            &lt;!&ndash;end::Google link=&ndash;&gt;-->
<!--          </div>-->
<!--          &lt;!&ndash;end::Col&ndash;&gt;-->
<!--        </div>-->
        <!--end::Login options-->
        <!--begin::Separator-->
<!--        <div class="separator separator-content my-14">-->
<!--          <span class="w-125px text-gray-500 fw-semibold fs-7">Or with email</span>-->
<!--        </div>-->
        <!--end::Separator-->
        <!--begin::Input group=-->
        <div class="fv-row mb-8">
          <!--begin::Email-->
          <input formControlName="email" type="email" class="form-control bg-transparent" id="loginEmailGroup" placeholder="Email" autocomplete="true">
          <div *ngIf="email?.hasError('email') && !email?.hasError('required')" class="invalid-field">
            Please enter a valid email address
          </div>
          <div *ngIf="email?.hasError('required') && email?.touched" class="invalid-field">
            Email is <strong>required</strong>
          </div>
          <!--end::Email-->
        </div>
        <!--end::Input group=-->
        <div class="fv-row mb-3">
          <!--begin::Password-->
          <div class="position-relative">
          <input formControlName="password" [attr.type]="isShowPass ? 'text' : 'password'" class="form-control bg-transparent" id="loginPasswordGroup" placeholder="Password" autocomplete="true">
            <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
												<i class="bi bi-eye-slash fs-2" [class]="!isShowPass ? 'bi-eye-slash' : 'bi-eye'" (click)="showPass($event)"></i>
											</span>
          </div>
          <div *ngIf="password?.hasError('required') && password?.touched" class="invalid-field">
            Password is <strong>required</strong>
          </div>
  
          <div *ngIf="password?.hasError('minlength') && password?.touched" class="invalid-field">
            Password min length is <strong>5</strong>
          </div>
  
          <div *ngIf="password?.hasError('maxlength') && password?.touched" class="invalid-field">
            Password max length is <strong>1000</strong>
          </div>
          
          <!--end::Password-->
        </div>
        <!--end::Input group=-->
        <!--begin::Wrapper-->
        <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
          <div></div>
          <!--begin::Link-->
          <a routerLink="/forgot-pass" class="link-primary">Forgot Password ?</a>
          <!--end::Link-->
        </div>
        <!--end::Wrapper-->
        <!--begin::Submit button-->
        <div class="d-grid mb-10">
          <button type="submit" id="kt_sign_in_submit" class="btn btn-primary" [disabled]="isLoading" (click)="recaptchaSolve()">
            <!--begin::Indicator label-->
            <span class="indicator-label" *ngIf="!isLoading; else loadingBlock">Sign In</span>
            <!--end::Indicator label-->
          </button>
        </div>
        <!--end::Submit button-->
        <!--begin::Sign up-->
        <div class="text-gray-500 text-center fw-semibold fs-6">Not a Member yet?
          <a routerLink="/sign-up" class="link-primary">Sign up</a></div>
        <!--end::Sign up-->
      </form>
      <!--end::Form-->
    </div>
 
 <ng-template #loadingBlock>
   <span>Please wait...
										<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
 </ng-template>
 
    <!--end::Content-->
<!--<monetizer-alert [type]="typeOfMessage" [message]="message"></monetizer-alert>-->
<!--<h2 class="text-center my-3">Log In</h2>-->
<!--<form class="w-100 my-3" [formGroup]="form">-->
<!--  <div class="form-group">-->
<!--    <div class="input-group mb-2">-->
<!--      <div class="input-group-prepend">-->
<!--        <div class="input-group-text"><img src="./assets/images/user.png" alt="" class="img-responsive"></div>-->
<!--      </div>-->
<!--      <input formControlName="email" type="email" class="form-control" id="loginEmailGroup" placeholder="Email" autocomplete="true">-->
<!--    </div>-->
<!--    <mat-error *ngIf="email?.hasError('email') && !email?.hasError('required')">-->
<!--      Please enter a valid email address-->
<!--    </mat-error>-->
<!--    <mat-error *ngIf="email?.hasError('required') && email?.touched" >-->
<!--      Email is <strong>required</strong>-->
<!--    </mat-error>-->
<!--  </div>-->
<!--  <div class="form-group">-->
<!--    <div class="input-group mb-2">-->
<!--      <div class="input-group-prepend">-->
<!--        <div class="input-group-text"><img src="./assets/images/lock.png" alt="" class="img-responsive"></div>-->
<!--      </div>-->
<!--      <input formControlName="password" type="password" class="form-control" id="loginPasswordGroup" placeholder="Password" autocomplete="true">-->
<!--    </div>-->
<!--    <div class="text-right">-->
<!--      <a routerLink="/forgot-pass" >Forgot password</a>-->
<!--    </div>-->
<!--  </div>-->
<!--  -->
<!--  -->
<!--  <button type="submit" class="btn btn-primary w-100  " [disabled]="form.invalid || (isLoading | async)" (click)="recaptchaSolve()"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="(isLoading | async)"></span> Log In</button>-->
<!--  -->
<!--</form>-->
<!--<div class="d-flex flex-column align-items-center">-->
<!--  <div class="both-lined"><p>or use</p></div>-->
<!--  <div class="social-container">-->
<!--    <a (click)="goToThirdParty('google')" class="social"><img src="./assets/images/google.svg" [width]="16"-->
<!--                                                              alt="Google" title="Google"></a>-->
<!--&lt;!&ndash;    <a (click)="goToThirdParty('github')" class="social"><img src="./assets/images/github.svg" [width]="16"&ndash;&gt;-->
<!--&lt;!&ndash;                                                              alt="GitHub" title="GitHub"></a>&ndash;&gt;-->
<!--  </div>-->
<!--</div>-->

<!--<p class="text-center">You don't have an account? <a routerLink="/sign-up">Register Here</a></p>-->

<!--<re-captcha size="invisible" #captchaRef (resolved)="$event && loginUser($event)"-->
<!--            [siteKey]="SITE_ID_NG">-->
<!--</re-captcha>-->
