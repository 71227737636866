<!--&lt;!&ndash;<section class="auth nk-wrap">&ndash;&gt;-->
<!--<div class="d-flex flex-column flex-root">-->
<!--    <div class="d-flex flex-column flex-lg-row flex-column-fluid">-->
<!--        <div class="d-flex flex-lg-row-fluid order-md-0 order-1">-->
<!--            &lt;!&ndash;begin::Content&ndash;&gt;-->
<!--            <div class="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">-->
<!--                &lt;!&ndash;begin::Image&ndash;&gt;-->
<!--                <img class="theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20" src="assets/images/auth/agency.png" alt="" />-->
<!--                &lt;!&ndash;end::Image&ndash;&gt;-->
<!--                &lt;!&ndash;begin::Title&ndash;&gt;-->
<!--                <h1 class="text-gray-800 fs-2qx fw-bold text-center mb-7">Welcome To LinkInsider</h1>-->
<!--                &lt;!&ndash;end::Title&ndash;&gt;-->
<!--                &lt;!&ndash;begin::Text&ndash;&gt;-->
<!--                <div class="text-gray-600 fs-base text-center fw-semibold">Lorem ipsum dolor sit amet, consectetur adipisicing elit. <br/>Ducimus, nostrum, numquam! Architecto dicta molestiae placeat quas quis vero voluptas! <br/>Dolorem enim esse minus quaerat? Accusantium adipisci aliquid, animi aspernatur distinctio eveniet ex expedita impedit magnam nobis placeat qui quod rem repellendus reprehenderit saepe tempora temporibus ullam! At eaque minima quas!</div>-->
<!--                &lt;!&ndash;end::Text&ndash;&gt;-->
<!--            </div>-->
<!--            &lt;!&ndash;end::Content&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 order-md-1 order-0">-->
<!--            &lt;!&ndash;begin::Wrapper&ndash;&gt;-->
<!--            <div class="bg-body d-flex flex-center rounded-4 w-md-600px p-10">-->
<!--                <router-outlet></router-outlet>-->
<!--            </div>-->
<!--        </div>-->
<!--        -->
<!--&lt;!&ndash;  <div class="container">&ndash;&gt;-->
<!--&lt;!&ndash;    <div class="d-flex align-items-center flex-column justify-content-center vh-100">&ndash;&gt;-->
<!--&lt;!&ndash;      <div class="form-wrapper shadow-sm rounded">&ndash;&gt;-->
<!--&lt;!&ndash;        <img src="./assets/images/logo-traffmonetizer.png" alt="Traffmonitizer" class="img-responsive form-logo">&ndash;&gt;-->
<!--&lt;!&ndash;        &ndash;&gt;-->
<!--&lt;!&ndash;        <router-outlet></router-outlet>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;      <div class="mt-2">&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;      <p class="text-center"><a href="https://traffmonetizer.com/terms-of-service" rel="noopener noreferrer" target="_blank">Terms of Service</a> | <a href="https://traffmonetizer.com/privacy-policy" rel="noopener noreferrer" target="_blank">Privacy Policy</a> </p>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;  </div>&ndash;&gt;-->
<!--</div>-->
<!--</div>-->
<div class="d-flex flex-column flex-root h-100vh">

    <!--begin::Authentication - Sign-in -->
    <div class="d-flex flex-column flex-lg-row flex-column-fluid">

        <!--begin::Body-->
        <div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2">
            <!--begin::Form-->
            <div class="d-flex flex-center flex-column flex-lg-row-fluid">
                <!--begin::Wrapper-->
                <router-outlet></router-outlet>
                <!--end::Wrapper-->
            </div>
            <!--end::Form-->

            <!--begin::Footer-->
            <div class="w-lg-500px d-flex flex-center px-10 mx-auto">

                <!--begin::Links-->
                <div class="d-flex fw-semibold text-primary fs-base gap-5">
                    <a href="https://seoinsider.com/terms-of-service" target="_blank">Terms</a>

                    <a href="https://seoinsider.com/pricing/" target="_blank">Plans</a>

                    <a href="https://seoinsider.com/contacts/" target="_blank">Contact Us</a>
                </div>
                <!--end::Links-->
            </div>
            <!--end::Footer-->
        </div>
        <!--end::Body-->

        <!--begin::Aside-->
        <div class="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1"
             style="background-image: url('assets/images/auth/login-bg.png')">
            <!--begin::Content-->
            <div class="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">

                <!-- begin:Logo -->
                <a href="https://seoinsider.com/" class="mb-0 mb-auto">
                    <img alt="SeoInsider Logo" src="assets/images/linksinsider-white.svg" class="h-60px h-lg-75px">
                </a>
                <!-- end:Logo -->
<!--                &lt;!&ndash; begin:Image &ndash;&gt;-->
<!--                <img class="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-450px mb-10 mb-lg-20" src="assets/images/app.bundle.webp" alt="" style="pointer-events: none;">-->
<!--                &lt;!&ndash; end:image &ndash;&gt;-->
                <!--begin::Title-->
                <h1 class="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">
                    Get ahead of the competition with SeoInsider
                </h1>
                <!--end::Title-->

                <!--begin::Text-->
                <div class="d-none d-lg-block text-white fs-base text-center mb-auto">
                    A valuable tool for anyone looking to improve their website's search engine rankings and grow their online presence through effective backlink building and analysis.
                </div>
                <!--end::Text-->
            </div>
            <!--end::Content-->
        </div>
        <!--end::Aside-->
    </div>
    <!--end::Authentication - Sign-in-->
</div>
