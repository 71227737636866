 <!--begin::Content-->
    <div class="w-md-400px">
      <!--begin::Form-->
      <form class="form w-100" [formGroup]="form">
        <!--begin::Heading-->
        <div class="text-center mb-10">
          <!--begin::Title-->
          <h1 class="text-dark fw-bolder mb-3">Setup New Password</h1>
          <!--end::Title-->
          <!--begin::Link-->
          <div class="text-gray-500 fw-semibold fs-6">Have you already reset the password ?
            <a routerLink="/sign-in" class="link-primary fw-bold">Sign in</a></div>
          <!--end::Link-->
        </div>
        <!--begin::Heading-->
        <!--begin::Input group-->
        <div class="fv-row mb-8">
          <!--begin::Wrapper-->
          <div class="mb-1">
            <!--begin::Input wrapper-->
            <div class="position-relative mb-3">
              <input formControlName="password" [attr.type]="isShowPass ? 'text' : 'password'" class="form-control bg-transparent" id="newPasswordGroup" placeholder="Create password (8+ characters)" autocomplete="new-password" (input)="checkPasswordStrength()" (paste)="checkPasswordStrength()" />
              <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
												<i class="bi bi-eye-slash fs-2" [class]="!isShowPass ? 'bi-eye-slash' : 'bi-eye'" (click)="showPass($event)"></i>
											</span>
            </div>
            <div class="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
              <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" [class.active]="passwordStrengthBar.value > 0"></div>
              <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" [class.active]="passwordStrengthBar.value > 1"></div>
              <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" [class.active]="passwordStrengthBar.value > 2"></div>
              <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px" [class.active]="passwordStrengthBar.value > 3"></div>
            </div>
            <!--end::Meter-->
          </div>
          <!--end::Wrapper-->
          <!--begin::Hint-->
          <div class="text-muted">Use 8 or more characters with a mix of letters, numbers &amp; symbols.</div>
          <div *ngIf="password?.hasError('required') && password?.touched" class="invalid-field">
            Password is <strong>required</strong>
          </div>
  
          <div *ngIf="password?.hasError('minlength') && password?.touched" class="invalid-field">
            Password min length is <strong>8</strong>
          </div>
  
          <div *ngIf="password?.hasError('maxlength') && password?.touched" class="invalid-field">
            Password max length is <strong>1000</strong>
          </div>
        </div>
        <!--end::Input group=-->
        <!--end::Input group=-->
        <div class="fv-row mb-8">
          <input formControlName="passwordConfirm" type="password" class="form-control bg-transparent" id="newPasswordConfirmGroup" placeholder="Repeat a password" autocomplete="new-password">
  
          <div *ngIf="form?.hasError('matching') && passwordConfirm?.touched" class="invalid-field">
            Password confirm doesn't match
          </div>
        </div>
        <!--end::Input group=-->
        
        <!--begin::Action-->
        <div class="d-grid mb-10">
          <button type="submit" class="btn btn-primary" [disabled]="loading" (click)="sendNewPass('')">
            <!--begin::Indicator label-->
            <span class="indicator-label" *ngIf="!loading; else loadingBlock">Sign up</span>
            <!--end::Indicator label-->
            <!--begin::Indicator progress-->
    
            <!--end::Indicator progress-->
          </button>
        </div>
        <!--end::Action-->
      </form>
      <!--end::Form-->
    </div>
    <!--end::Content-->
<!--<h6 class="text-center my-3">Enter your new password in the fields below</h6>-->

<!--<form class="w-100 my-3" [formGroup]="form">-->
<!--  <div class="form-group">-->
<!--    <div class="input-group mb-2">-->
<!--      <div class="input-group-prepend">-->
<!--        <div class="input-group-text"><img src="./assets/images/lock.png" alt="" class="img-responsive"></div>-->
<!--      </div>-->
<!--      <input formControlName="password" type="password" class="form-control" id="resetPasswordGroup" placeholder="Password">-->
<!--    </div>-->
<!--    <mat-error *ngIf="password?.hasError('minlength')">-->
<!--      Min length of password <strong>8</strong>-->
<!--    </mat-error>-->
<!--    <mat-error *ngIf="password?.hasError('required') && password?.touched">-->
<!--      Password is <strong>required</strong>-->
<!--    </mat-error>-->
<!--  </div>-->
<!--  <div class="form-group">-->
<!--    <div class="input-group mb-2">-->
<!--      <div class="input-group-prepend">-->
<!--        <div class="input-group-text"><img src="./assets/images/lock.png" alt="" class="img-responsive"></div>-->
<!--      </div>-->
<!--      <input formControlName="passwordConfirm" type="password" class="form-control" id="resetPasswordConfirmGroup" placeholder="Repeat password">-->
<!--    </div>-->
<!--    <mat-error *ngIf="passwordConfirm?.hasError('required')">-->
<!--      Password is <strong>required</strong>-->
<!--    </mat-error>-->
<!--    <mat-error *ngIf="passwordConfirm?.hasError('matching') && passwordConfirm?.touched">-->
<!--      Password must <strong>matches</strong>-->
<!--    </mat-error>-->
<!--  </div>-->
<!--  <button type="submit" class="btn btn-primary w-100  " [disabled]="form.invalid" (click)="captchaRef.execute()">Submit</button>-->
<!--</form>-->

<!--<p class="text-center">Back to <a routerLink="/sign-in">Sign In</a></p>-->

<!--<re-captcha size="invisible" #captchaRef (resolved)="$event && sendNewPass($event)"-->
<!--            [siteKey]="SITE_ID_NG">-->
<!--</re-captcha>-->

 <ng-template #loadingBlock>
   <span>Please wait...
										<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
 </ng-template>
