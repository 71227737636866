import { Component, OnInit } from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "../../store/app.reducer";
import {signOut} from "../../store/user/user.actions";
import {Observable} from "rxjs";
import {User} from "../../models/user.model";
import {getUserData} from "../../store/user/user.selector";

@Component({
  selector: 'app-plug-layout',
  templateUrl: './plug-layout.component.html',
  styleUrls: ['./plug-layout.component.scss']
})
export class PlugLayoutComponent implements OnInit {

  userData$!: Observable<User | undefined>
  constructor(
      private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.userData$ = this.store.select(getUserData)
  }
  
  logout() {
    this.store.dispatch(signOut());
  }

}
