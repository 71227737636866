<div class="container-fluid">
	<div class="card mb-5 mb-xl-10">
		<!--begin::Card header-->
		<div class="card-header border-0 cursor-pointer" role="button" aria-expanded="true">
			<!--begin::Card title-->
			<div class="card-title m-0">
				<h3 class="fw-bold m-0">Profile Details</h3>
			</div>
			<!--end::Card title-->
		</div>
		<!--begin::Card header-->
		<!--begin::Content-->
		<div class="collapse show">
			<!--begin::Form-->
			<form class="form fv-plugins-bootstrap5 fv-plugins-framework" [formGroup]="form" (submit)="updateUserData()">
				<!--begin::Card body-->
				<div class="card-body border-top p-9">
					<!--begin::Input group-->
					<div class="row mb-6">
						<!--begin::Label-->
						<label class="col-lg-4 col-form-label fw-semibold fs-6">Email</label>
						<!--end::Label-->
						<!--begin::Col-->
						<div class="col-lg-8">
							<!--begin::Row-->
							<div class="row">
								<!--begin::Col-->
								<div class="col-lg-12 fv-row fv-plugins-icon-container">
									<input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Email" [value]="userData.email" readonly [disabled]="true">
									<div class="fv-plugins-message-container invalid-feedback"></div></div>
								<!--end::Col-->
							</div>
							<!--end::Row-->
						</div>
						
						<!--end::Col-->
					</div>
					<!--end::Input group-->
					
					<!--begin::Input group-->
					<div class="row mb-6">
						<!--begin::Label-->
						<label class="col-lg-4 col-form-label fw-semibold fs-6">Name</label>
						<!--end::Label-->
						<!--begin::Col-->
						<div class="col-lg-8">
							<!--begin::Row-->
							<div class="row">
								<!--begin::Col-->
								<div class="col-lg-12 fv-row fv-plugins-icon-container">
									<input type="text" formControlName="name" class="form-control form-control-lg mb-3 mb-lg-0" placeholder="Name">
									<div class="fv-plugins-message-container invalid-feedback"></div></div>
								<!--end::Col-->
							</div>
							<!--end::Row-->
						</div>
						
						<!--end::Col-->
					</div>
					<!--end::Input group-->
					<!--begin::Input group-->
					<div class="row mb-6">
						<!--begin::Label-->
						<label class="col-lg-4 col-form-label required fw-semibold fs-6">Date Format</label>
						<!--end::Label-->
						<!--begin::Col-->
						<div class="col-lg-8 fv-row fv-plugins-icon-container">
							<div class="row align-items-center">
								<div class="col-lg-6"><div class="form-control-wrap">
									<mat-form-field appearance="outline">
										<!--									<mat-label>Domain Type</mat-label>-->
										<mat-select formControlName="date_format" [value]="currentFormat" (valueChange)="changeFormat($event)">
											<mat-option *ngFor="let dateFormat of dateFormatItems" [value]="dateFormat.value">
												{{dateFormat.key}}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div></div>
								<div class="col-lg-3"><div class="form-control-wrap">
									<mat-form-field appearance="outline">
										
										<!--									<mat-label>Domain Type</mat-label>-->
										<mat-select formControlName="date_format_separator" [value]="currentDelimeter" (valueChange)="changeDelimeter($event)">
											<mat-option *ngFor="let delimeter of dateDelimitersItems" [value]="delimeter.value">
												{{delimeter.key}}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div></div>
								<div class="col-lg-3"><code>Example: {{fullDateExample}}</code></div>
							</div>
						</div>
					</div>
					<!--end::Input group-->
					<!--begin::Input group-->
					<div class="row mb-6">
						<!--begin::Label-->
						<label class="col-lg-4 col-form-label fw-semibold fs-6">Password</label>
						<!--end::Label-->
						<!--begin::Col-->
						<div class="col-lg-8">
							<!--begin::Row-->
							<div class="row">
								<!--begin::Col-->
								<div class="col-lg-12 fv-row fv-plugins-icon-container">
									<button type="button" class="btn btn-light btn-active-light-primary" (click)="resetVisible=true" *ngIf="!resetVisible">Reset Password</button>
									<div  *ngIf="resetVisible">
										<div class="fv-row mb-0 fv-plugins-icon-container">
											<label class="form-label fs-6 fw-bold mb-3">Current Password</label>
											<input type="password" class="form-control form-control-lg" formControlName="old_password">
											<div class="fv-plugins-message-container invalid-feedback"></div></div>
										<div class="fv-row mb-0 fv-plugins-icon-container">
											<label  class="form-label fs-6 fw-bold mb-3">New Password</label>
											<input type="password" class="form-control form-control-lg" formControlName="password" autocomplete="off">
											<mat-error *ngIf="password?.hasError('minlength')">
												Min length of password <strong>8</strong>
											</mat-error>
										</div>
										<div class="fv-row mb-0 fv-plugins-icon-container">
											<label class="form-label fs-6 fw-bold mb-3">Confirm Password</label>
											<input type="password" class="form-control form-control-lg" formControlName="confirm_password" autocomplete="off">
											<mat-error *ngIf="passwordConfirm?.hasError('matching') && password?.touched">
												New Password must <strong>matches</strong>
											</mat-error>
										</div>
									</div>
								</div>
								<!--end::Col-->
							</div>
							<!--end::Row-->
						</div>
						
						<!--end::Col-->
					</div>
					<!--end::Input group-->
					
				</div>
				<!--end::Card body-->
				<!--begin::Actions-->
				<div class="card-footer d-flex justify-content-between py-6 px-9">
					<div>
						<button type="button" class="btn btn-border-danger btn-sm" (click)="deleteAccount()">Delete Account</button>
					</div>
					<div>
						<button type="button" class="btn btn-light btn-active-light-primary me-2 btn-sm" (click)="resetForm()">Discard</button>
						<button type="submit" class="btn btn-primary btn-sm" [disabled]="checkDisable || loading">
							<span class="indicator-label" *ngIf="!loading; else loadingBlock">Save Changes</span>
						</button>
					</div>
				</div>
				<!--end::Actions-->
				<input type="hidden"></form>
		</div>
	</div>
</div>

<ng-template #loadingBlock>
   <span>Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
</ng-template>
