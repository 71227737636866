import { Component, Input } from '@angular/core';
import {BalanceState} from "../../../../store/balance/balance.reducer";
import {TariffData} from "../../../../models/tariff-data.model";

@Component({
  selector: 'app-balance-view',
  templateUrl: './balance-view.component.html',
  styleUrls: ['./balance-view.component.scss'],
})
export class BalanceViewComponent {

  @Input() balanceData: BalanceState | null = null;
  @Input() tariffData: TariffData | null = null;
  
  constructor() {
  }
  
  get percentageDomains() {
    if (!this.balanceData) return 0;
    if (!this.balanceData.domains_limit) return 0;
    return Math.round((this.balanceData.domains_used / this.balanceData.domains_limit) * 10000) / 100;
  }
  
  get percentageRows() {
    if (!this.balanceData) return 0;
    if (!this.balanceData.rows_per_month_limit) return 0;
    return Math.round(this.balanceData.rows_per_month_used / this.balanceData.rows_per_month_limit * 10000) / 100;
  }
}
