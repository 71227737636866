import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AccountFacade} from "../../account.facade";
import {User} from "../../../core/models/user.model";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {formatDate} from "devextreme/localization";
import {CommonService} from "../../../core/services/common.service";
import {UpdateUserDataModel} from "../../models/update-user-data.model";
import {shallowEqual} from "../../../core/utils/utils";
import {Observable} from "rxjs";
import {MustMatch} from "../../../auth/containers";
import {ConfirmDialogComponent} from "../../components/confirm-dialog/confirm-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  userData!: User;
  
  currentDate: Date = new Date();
  
  form!: FormGroup
  
  currentFormat: string = 'd m Y';
  
  fullDateFormat: string = 'd.m.Y';
  fullDateExample: string = '';
  
  currentDelimeter: string = '.';
  
  oldData!: UpdateUserDataModel;
  
  loading!: boolean;
  
  resetVisible = false;
  
  @Output() updateUser: EventEmitter<any> = new EventEmitter<any>();
  
  dateDelimitersItems: { key: string, value: string }[] = [{key: '.', value: '.'}, {key: '-', value: '-'}, {
    key: '/', value: '/'
  }];
  
  dateFormatItems: { key: string, value: string }[] = [];
  
  deleteAccountLoader: boolean = false;
  constructor(private facade: AccountFacade, private cms: CommonService, private _snackBar: MatSnackBar, public dialog: MatDialog) {
    this.dateFormatItems = [{
      key: 'dd mm yyyy (' + formatDate(this.currentDate, 'dd MM yyyy') + ')', value: 'd m Y'
    }, {
      key: 'mm dd yyyy (' + formatDate(this.currentDate, 'MM dd yyyy') + ')', value: 'm d Y'
    }, {
      key: 'yyyy dd mm (' + formatDate(this.currentDate, 'yyyy dd MM') + ')', value: 'Y d m'
    }, {key: 'yyyy mm dd (' + formatDate(this.currentDate, 'yyyy MM dd') + ')', value: 'Y m d'}];
  }

  ngOnInit(): void {
    this.facade.getUserData().subscribe(data => {
      if (data) {
        this.userData = data;
        this.currentFormat = data.date_format.split(data.date_format_separator).join(' ');
        this.currentDelimeter = data.date_format_separator;
        this.calculateDateFormat()
      }
    })
    
    this.facade.getLoading().subscribe(
      load => this.loading = load
    );
    this.formInit();
    this.oldData = {...this.form.value};
    
  }
  
  /**
   * Getter for old password control
   * @type {AbstractControl}
   */
  get oldPassword(): AbstractControl | null {
    return this.form.get('old_password');
  }
  
  /**
   * Getter for password control
   * @type {AbstractControl}
   */
  get password(): AbstractControl | null {
    return this.form.get('password');
  }
  
  /**
   * Getter for form confirm password control
   * @type {AbstractControl}
   */
  get passwordConfirm(): AbstractControl | null {
    return this.form.get('confirm_password');
  }
  
  formInit() {
    this.form = new FormGroup({
      name: new FormControl(this.userData.name, Validators.nullValidator),
      date_format: new FormControl(this.userData.date_format, Validators.required),
      date_format_separator: new FormControl(this.userData.date_format_separator, Validators.required),
      old_password: new FormControl('', [
        // Validators.required,
      ]),
      password: new FormControl('', [
        // Validators.required,
        Validators.minLength(8)
      ]),
      confirm_password: new FormControl('', [
      ])
    }, {validators: MustMatch('password', 'confirm_password')})
  }
  
  changeFormat(format: string) {
    this.currentFormat = format;
    this.calculateDateFormat();
  }
  
  changeDelimeter(delimeter: string) {
    this.currentDelimeter = delimeter;
    this.calculateDateFormat();
  }
  
  calculateDateFormat() {
    this.fullDateFormat = this.cms.convertDateTime(this.currentFormat.split(' ').join(this.currentDelimeter))
    this.fullDateExample = formatDate(this.currentDate, this.fullDateFormat);
  }
  
  get checkDisable() {
    return shallowEqual(this.form.value, this.oldData);
  }
  
  updateUserData() {
    if (this.form.valid) {
      this.facade.updateUserData(this.form.value);
      /* finish */
      this.oldData = {...this.form.value}
    }
  }
  
  resetForm() {
    this.form.setValue(this.oldData);
  }
  
  deleteAccount() {
    this.dialog.open(ConfirmDialogComponent, {
      width: "400px",
      maxWidth: "100%",
      data: {
        message: "All account information and data <strong>will be deleted without possibility to restore</strong>. <br/>Are you sure you want to delete your account?",
        title: 'Delete account confirmation',
        type: 'danger'
      }
    }).afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.deleteAccountLoader = true;
        this.facade.deleteUser().subscribe(data => {
          if (data.success) {
            this._snackBar.open('Your account has been deleted.', 'X', {
              duration: 3000,
              panelClass: ['snack-bar-success']
            });
            this.deleteAccountLoader = false;
            this.facade.logOut();
          }
        })
      }
    })
  }

}
