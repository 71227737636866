import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {User, UserSocialAccount} from "../../core/models/user.model";
import {UpdateUserDataModel} from "../models/update-user-data.model";
import {IPayment} from "../models/payment.model";

@Injectable()
export class AccountApi {
	/**
	 * Constructor for AccountApi service
	 * @param {HttpClient} httpClient
	 */
	constructor(private httpClient: HttpClient) { }
	
	updateUser(updateUserData: UpdateUserDataModel): Observable<{ data: { user: User} }> {
		const postData = {
			name: updateUserData.name,
			date_format: updateUserData.date_format,
			date_format_separator: updateUserData.date_format_separator,
			old_password: (updateUserData.old_password) ? updateUserData.old_password : undefined,
			password: (updateUserData.password) ? updateUserData.password : undefined,
			password_confirmation: (updateUserData.confirm_password) ? updateUserData.confirm_password : undefined,
		};
		return this.httpClient.post<{data: {user: User}}>('backlinks_api/api/user/profile/save_user_profile', postData);
	}
	
	deleteUser(): Observable<{ data: { success: boolean} }> {
		return this.httpClient.get<{data: { success: boolean}}>('backlinks_api/api/user/profile/delete_user_account');
	}
	
	revokeAccount(provider: string): Observable<{ data: {user: User} }> {
		const postData = {
			provider
		}
		return this.httpClient.post<{ data: {user: User} }> ('backlinks_api/api/insider/social_account/revoke', postData);
	}
	
	goToThirdParty(part: string): Observable<{ target_url: string  }> {
		return this.httpClient.get<{ target_url: string }>(`backlinks_api/api/auth/get_provider_target_url/${part}`);
	}
	
	getGoogleDomains(): Observable<string[]> {
		return this.httpClient.get<string[]>('backlinks_api/api/projects/get');
	}
	
	getGoogleDomainsDirectly(): Observable<string[]> {
		return this.httpClient.get<string[]>('backlinks_api/api/projects/get_now');
	}
	
	getSocialAccount(): Observable<{ data: UserSocialAccount }> {
		const postData = {
			provider: 'google'
		}
		return this.httpClient.post<{ data: UserSocialAccount }>('backlinks_api/api/insider/social_account/get', postData)
	}
	
	getPayments(): Observable<{ data: IPayment[] }> {
		return this.httpClient.get<{ data: IPayment[] }>('backlinks_api/api/billings/get_invoices_ng')
	}
}
