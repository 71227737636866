import {createAction, props} from "@ngrx/store";
import {IBalanceError} from "./balance.reducer";

export const updateBalanceData = createAction(
  '[Update balance data]',
  props<{domains_limit: number | string | null, domains_used: number | string | null, rows_per_report_limit: number | string | null, rows_per_month_limit: number | string | null, rows_per_month_used: number | string | null}>()
);

export const setBalanceData = createAction(
  '[Set balance data]',
  props<{domains_limit: number, domains_used: number, rows_per_report_limit: number, rows_per_month_limit: number, rows_per_month_used: number}>()
);

export const initBalanceData = createAction(
  '[Load balance data]',
  props<{domain: string | null}>()
);

export const setErrorBalanceData = createAction(
	'[set error balance]',
	props<{errors: Partial<IBalanceError> | null}>()
)
