import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {
  
  
  /**
   * Check if left menu is opened
   * @type {boolean}
   */
  isLeftMenuShown: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
  }
  
  /**
   * Show/hide left menu toggle handler
   * @return {void}
   */
  menuToggle(): void {
    this.isLeftMenuShown = !this.isLeftMenuShown;
  }

}
