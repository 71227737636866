<section class="header nk-body npc-default has-sidebar no-touch">
  <div [@enter] class="nk-header nk-header-fixed is-light">
    <div class="container-fluid">
      <div class="nk-header-wrap">
        <button mat-icon-button class="mobile-menu-icon" aria-label="Menu icon" (click)="menuToggleHandler()">
          <mat-icon>menu</mat-icon>
        </button>
        <div *ngIf="(domainData$ | async) as domainData">
          <app-domain-control
            *ngIf="domainData.domain"
            [domainData]="domainData"
            (saveDomainDataToState)="saveDomainDataToState($event)">
          </app-domain-control>
        </div>

        <div class="nk-header-tools">
          <app-user-menu
            [isUserAuthenticated]="isUserAuthenticated$ | async"
            [userData] = "userData$ | async"
            [isDemo] = "isUserDemo$ | async"
            (loginEvent)="login()"
            (logoutEvent)="logout()">
          </app-user-menu>
        </div>
      </div>
    </div>
  </div>

</section>
<div class="demo-plate py-2 px-4 fs-4 fw-bold" *ngIf="(isUserDemo$ | async)"><mat-icon class="mat-icon mat-tooltip-trigger material-icons mat-icon-inline">info</mat-icon> This is Demo page.
  To start
  your
  free 7-day
  trial select a
  suitable plan from this page. <a routerLink="select-plans"> Select Plan</a>
  </div>
