    <!--begin::Content-->
    <div class="w-md-400px">
      <!--begin::Form-->
      <form class="form w-100" [formGroup]="form" (submit)="recaptchaSolve()">
        <!--begin::Heading-->
        <div class="text-center mb-10">
          <h1 class="text-dark fw-bolder mb-3">Forgot Password?</h1>
          <div class="text-gray-500 fw-semibold fs-6">Enter your email to reset your password.</div>
        </div>

        <div class="fv-row mb-8">
          <input type="text" placeholder="Email" formControlName="email" autocomplete="off" class="form-control bg-transparent" />
          <mat-error *ngIf="email?.hasError('required') && email?.touched" >
            Email is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="email?.hasError('email') && email?.touched" >
            Please enter a valid email address
          </mat-error>
        </div>
        <div class="d-flex flex-wrap justify-content-center pb-lg-0">
          <button type="submit" class="btn btn-primary me-4" [disabled]="loading">
            <span class="indicator-label" *ngIf="!loading; else loadingBlock">Submit</span>
          </button>
          <a routerLink="/sign-in" class="btn btn-light">Cancel</a>
        </div>
      </form>
      <!--end::Form-->
    </div>
    <!--end::Content-->
<!--<h6 class="text-center my-3">Enter the e-mail that was used for registration</h6>-->
<!--<form class="w-100 my-3" [formGroup]="form">-->
<!--  <div class="form-group">-->
<!--    <div class="input-group mb-2">-->
<!--      <div class="input-group-prepend">-->
<!--        <div class="input-group-text"><img src="./assets/images/user.png" alt="" class="img-responsive"></div>-->
<!--      </div>-->
<!--      <input formControlName="email" type="email" class="form-control" id="forgotEmailGroup" placeholder="Email">-->
<!--    </div>-->
<!--    <mat-error *ngIf="email?.hasError('email') && !email?.hasError('required')">-->
<!--      Please enter a valid email address-->
<!--    </mat-error>-->
<!--    <mat-error *ngIf="email?.hasError('required') && email?.touched" >-->
<!--      Email is <strong>required</strong>-->
<!--    </mat-error>-->
<!--  </div>-->
<!--  -->
<!--  <button type="submit" class="btn btn-default w-100  " [disabled]="form.invalid || (isLoading | async)" (click)="captchaRef.execute()"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="(isLoading | async)"></span> Send</button>-->
<!--</form>-->

<!--<p class="text-center">Back to <a routerLink="/sign-in">Sign In</a></p>-->

<!--<re-captcha size="invisible" #captchaRef (resolved)="$event && sendEmailForReset($event)"-->
<!--            [siteKey]="SITE_ID_NG">-->
<!--</re-captcha>-->

<ng-template #loadingBlock>
  <span>Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
</ng-template>
