<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content class="mb-2">
	<p [innerHtml]="data.message"></p>
</div>
<div mat-dialog-actions align="end">
	<button type="button" class="btn btn-light btn-active-light-primary me-2 btn-sm" (click)="closeDialog()">Discard</button>
	<button type="submit" class="btn btn-sm" [ngClass]="data.type ? 'btn-' + data.type : 'btn-primary'" (click)="confirm()" cdkFocusInitial>
		<span class="indicator-label">Confirm</span>
	</button>
</div>
