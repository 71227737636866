import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountRoutingModule } from './account-routing.module';
import { ProfileComponent } from './containers/profile/profile.component';
import {AccountFacade} from "./account.facade";
import {AccountState} from "./state/account.state";
import {AccountApi} from "./api/account.api";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {RequestInterceptorService} from "../core/services/app.interceptor";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {CommonService} from "../core/services/common.service";
import { GoogleConnectionComponent } from './containers/google-connection/google-connection.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import { BillingComponent } from './containers/billing/billing.component';
import {DxDataGridModule} from "devextreme-angular";


@NgModule({
  declarations: [
    ProfileComponent,
    GoogleConnectionComponent,
    ConfirmDialogComponent,
    BillingComponent
  ],
  imports: [CommonModule, AccountRoutingModule, ReactiveFormsModule, MatInputModule, MatSelectModule, MatTooltipModule, MatDialogModule, DxDataGridModule],
  providers: [
      AccountApi,
      AccountFacade,
      AccountState,
      CommonService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: RequestInterceptorService,
        multi: true
      }
  ]
})
export class AccountModule { }
