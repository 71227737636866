<div class="nk-header-search">
  <div *ngIf="innerWidth <= 768" class="mobile-domain" (click)="toggleDomainContainer()">
    <span class="mobile-domain-text">{{domainData?.domain}}</span>
    <mat-icon [inline]="true">arrow_drop_down</mat-icon>
  </div>
  <ng-container *ngTemplateOutlet="dialogRef"></ng-container>
<!--  <div *ngIf="innerWidth > 768">-->
<!--    <ng-container *ngTemplateOutlet="dialogRef" ></ng-container>-->
<!--  </div>-->
<!--  <div *ngIf="innerWidth <= 768">-->
<!--    <button type="button" class="btn btn-sm btn-light-primary" (click)="openDialog()">Apply</button>-->
<!--  </div>-->
</div>

<ng-template #dialogRef>
  <form [formGroup]="form" (submit)="onSubmit()" class="domain-form-wrapper" [class.mobile]="innerWidth <= 768" [class.isShown]="showContainer"  [@fadeInOnEnter] [@fadeOutOnLeave] *ngIf="showContainer">
    <div class="form-control-wrap">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Domain</mat-label>
        <input formControlName="domain" matInput [value]="domainData?.domain">
      </mat-form-field>
      <!--          <input formControlName="domain" type="text" class="form-control" id="default-04" placeholder="example.com">-->
    </div>
    <div class="form-control-wrap">
      <mat-form-field appearance="outline">
        <mat-label>Domain Type</mat-label>
        <mat-select formControlName="domainTypeId" [value]="domainData?.domainTypeId">
          <mat-optgroup *ngFor="let group of domainTypesGroup" [label]="group.name"
                        [disabled]="group.disabled">
            <mat-option *ngFor="let domainType of group.domainTypes" [value]="domainType.value">
              {{domainType.viewValue}}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-control-wrap">
      <button type="submit" class="btn btn-sm btn-primary submit-domain">Apply</button>
    </div>
    
    <ul class="nk-block-tools">
      <li>
      
      </li>
      <li class="ml-3">
        
        
        <!--        <div [class.show]="isSelectOpened" class="drodown">-->
        <!--          <a class="dropdown-toggle btn btn-outline-light btn-white" data-toggle="dropdown">*.domain/*</a>-->
        <!--          <div [class.show]="isSelectOpened" class="dropdown-menu dropdown-menu-right"-->
        <!--               style="position: absolute;transform: translate3d(222px, 52px, 0px);top: 0px;left: 0px;will-change: transform;"-->
        <!--               x-placement="bottom-end">-->
        <!--            <ul class="link-list-opt no-bdr">-->
        <!--              <li><a href="#"><span>*.domain/*</span></a>-->
        <!--                domain with subdomains-->
        <!--              </li>-->
        <!--              <li><a href="#"><span>https://domain.com/</span></a>-->
        <!--                URL-->
        <!--              </li>-->
        <!--              <li><a href="#"><span>domain/*</span></a>-->
        <!--                domain without subdomains-->
        <!--              </li>-->
        <!--              <li><a href="#"><span>domain.com/path/*</span></a>-->
        <!--                Prefix-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--          </div>-->
        <!--        </div>-->
      </li>
      <li class="ml-3">
      
      </li>
    </ul>
  </form>
</ng-template>
