<div class="balance-wrapper">
  <h5>Usage</h5>
  <div class="d-flex align-items-center flex-column mt-3 w-100">
    <div class="d-flex justify-content-between w-100 mt-auto mb-2">
      <span class="fw-bolder fs-6 text-dark">Domains</span>
      <span class="fw-bold fs-6 text-gray-400" *ngIf="balanceData">{{balanceData.domains_used | number}} of {{balanceData.domains_limit | number}}</span>
    </div>
    
    <div class="h-8px mx-3 w-100 bg-light-primary rounded">
      <div class="bg-primary rounded h-8px" role="progressbar" [style.width.%]="percentageDomains"></div>
    </div>
  </div>
  <div class="d-flex align-items-center flex-column mt-3 w-100">
    <div class="d-flex justify-content-between w-100 mt-auto mb-2">
      <span class="fw-bolder fs-6 text-dark">Rows</span>
      <span class="fw-bold fs-6 text-gray-400" *ngIf="balanceData">{{balanceData.rows_per_month_used | number}} of {{balanceData.rows_per_month_limit | number}}</span>
    </div>
    
    <div class="h-8px mx-3 w-100 bg-light-primary rounded">
      <div class="bg-primary rounded h-8px" role="progressbar" [style.width.%]="percentageRows"></div>
    </div>
  </div>
  <a routerLink="/plans" *ngIf="tariffData?.tariff_title != 'Ultimate plan'" class="btn btn-primary w-100 mt-3">Upgrade plan</a>
</div>
<!--<div class="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">-->
<!--  <div class="d-flex justify-content-between w-100 mt-auto mb-2">-->
<!--    <span class="fw-semibold fs-6 text-gray-400">Profile Compleation</span>-->
<!--    <span class="fw-bold fs-6">50%</span>-->
<!--  </div>-->
<!--  -->
<!--  <div class="h-5px mx-3 w-100 bg-light mb-3">-->
<!--    <div class="bg-success rounded h-5px" role="progressbar" style="width: 50%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>-->
<!--  </div>-->
<!--</div>-->
