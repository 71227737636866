 <!--begin::Content-->
    <div class="w-md-400px">
      <!--begin::Form-->
      <form class="form w-100" novalidate="novalidate" id="kt_sign_up_form" action="#" autocomplete="off" [formGroup]="form" *ngIf="registrationEnabled">
        <!--begin::Heading-->
        <div class="text-center mb-11">
          <!--begin::Title-->
          <h1 class="text-dark fw-bolder mb-3">Sign Up</h1>
          <!--end::Title-->
          <!--begin::Subtitle-->
<!--          <div class="text-gray-500 fw-semibold fs-6">Your Social Campaigns</div>-->
          <!--end::Subtitle=-->
        </div>
        <!--begin::Login options-->
<!--        <div class="row g-3 mb-9 justify-content-center">-->
<!--          &lt;!&ndash;begin::Col&ndash;&gt;-->
<!--          <div class="col-md-6">-->
<!--            &lt;!&ndash;begin::Google link=&ndash;&gt;-->
<!--            <button (click)="goToThirdParty('google')" class="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">-->
<!--              <img alt="Logo" src="assets/images/brand-logos/google-icon.svg" class="h-15px me-3" />Sign in with Google</button>-->
<!--            &lt;!&ndash;end::Google link=&ndash;&gt;-->
<!--          </div>-->
<!--          &lt;!&ndash;end::Col&ndash;&gt;-->
<!--&lt;!&ndash;          &lt;!&ndash;begin::Col&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="col-md-6">&ndash;&gt;-->
<!--&lt;!&ndash;            &lt;!&ndash;begin::Google link=&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;            <a href="#" class="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">&ndash;&gt;-->
<!--&lt;!&ndash;              <img alt="Logo" src="assets/media/svg/brand-logos/apple-black.svg" class="theme-light-show h-15px me-3" />&ndash;&gt;-->
<!--&lt;!&ndash;              <img alt="Logo" src="assets/media/svg/brand-logos/apple-black-dark.svg" class="theme-dark-show h-15px me-3" />Sign in with Apple</a>&ndash;&gt;-->
<!--&lt;!&ndash;            &lt;!&ndash;end::Google link=&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          &lt;!&ndash;end::Col&ndash;&gt;&ndash;&gt;-->
<!--        </div>-->
<!--        &lt;!&ndash;end::Login options&ndash;&gt;-->
<!--        &lt;!&ndash;begin::Separator&ndash;&gt;-->
<!--        <div class="separator separator-content my-14">-->
<!--          <span class="w-125px text-gray-500 fw-semibold fs-7">Or with email</span>-->
<!--        </div>-->
        <!--end::Separator-->
        <!--begin::Input group=-->
        <div class="fv-row mb-8">
          <!--begin::Name-->
          <input formControlName="name" type="text" class="form-control bg-transparent" id="loginNameGroup" placeholder="Your name" autocomplete="off">

          <div *ngIf="name?.hasError('minlength') && name?.touched" class="invalid-field">
                  Min length of name <strong>2</strong> symbols
          </div>
          <!--end::Name-->
        </div>
        <div class="fv-row mb-8">
          <!--begin::Email-->
          <input formControlName="email" type="email" class="form-control bg-transparent" id="loginEmailGroup" placeholder="Email" autocomplete="off">
          <!--end::Email-->
          <div *ngIf="email?.hasError('email') && !email?.hasError('required')" class="invalid-field">
            Please enter a valid email address
          </div>
          <div *ngIf="email?.hasError('required') && email?.touched" class="invalid-field">
            Email is <strong>required</strong>
          </div>
        </div>
        <!--begin::Input group-->
        <div class="fv-row mb-8" data-kt-password-meter="true">
          <!--begin::Wrapper-->
          <div class="mb-1">
            <!--begin::Input wrapper-->
            <div class="position-relative mb-3">
              <input formControlName="password" [attr.type]="isShowPass ? 'text' : 'password'" class="form-control bg-transparent" id="newPasswordGroup" placeholder="Create password (8+ characters)" autocomplete="new-password" (input)="checkPasswordStrength()" (paste)="checkPasswordStrength()">
              <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
												<i class="bi bi-eye-slash fs-2" [class]="!isShowPass ? 'bi-eye-slash' : 'bi-eye'" (click)="showPass($event)"></i>
											</span>
            </div>
            <!--end::Input wrapper-->
            <!--begin::Meter-->
            <div class="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
              <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" [class.active]="passwordStrengthBar.value > 0"></div>
              <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" [class.active]="passwordStrengthBar.value > 1"></div>
              <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" [class.active]="passwordStrengthBar.value > 2"></div>
              <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px" [class.active]="passwordStrengthBar.value > 3"></div>
            </div>
            <!--end::Meter-->
          </div>
          <!--end::Wrapper-->
          <!--begin::Hint-->
          <div class="text-muted">Use 8 or more characters with a mix of letters, numbers &amp; symbols.</div>

          <div *ngIf="password?.hasError('required') && password?.touched" class="invalid-field">
            Password is <strong>required</strong>
          </div>

          <div *ngIf="password?.hasError('minlength') && password?.touched" class="invalid-field">
            Password min length is <strong>8</strong>
          </div>

          <div *ngIf="password?.hasError('maxlength') && password?.touched" class="invalid-field">
            Password max length is <strong>1000</strong>
          </div>
          <!--end::Hint-->
        </div>
        <!--end::Input group=-->
        <!--end::Input group=-->
        <div class="fv-row mb-8">
          <!--begin::Repeat Password-->
          <input formControlName="passwordConfirm" type="password" class="form-control bg-transparent" id="newPasswordConfirmGroup" placeholder="Repeat a password" autocomplete="new-password">

          <div *ngIf="form?.hasError('matching') && passwordConfirm?.touched" class="invalid-field">
            Password confirm doesn't match
          </div>
          <!--end::Repeat Password-->
        </div>
        <!--end::Input group=-->
        <!--begin::Accept-->
        <div class="fv-row mb-8">
          <label class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="toc" formControlName="acceptTerms" />
            <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">I agree to the
										<a href="https://seoinsider.com/terms-of-service/"
                       class="ms-1 link-primary">Terms of Service</a> and <a href="https://seoinsider.com/privacy-policy/" class="ms-1 link-primary">Privacy Policy</a></span>
          </label>
          <div *ngIf="accept?.hasError('required') && showErrors$.value" class="invalid-field">
            This field is <strong>required</strong>
          </div>
        </div>
        <!--end::Accept-->
        <!--begin::Submit button-->
        <div class="d-grid mb-10">
          <button type="submit" id="kt_sign_up_submit" class="btn btn-primary" [disabled]="isLoading" (click)="recaptchaSolve()">
            <!--begin::Indicator label-->
            <span class="indicator-label" *ngIf="!isLoading; else loadingBlock">Sign up</span>
            <!--end::Indicator label-->
            <!--begin::Indicator progress-->

            <!--end::Indicator progress-->
          </button>
        </div>
        <!--end::Submit button-->
        <!--begin::Sign up-->
        <div class="text-gray-500 text-center fw-semibold fs-6">Already have an Account?
          <a routerLink="/sign-in" class="link-primary fw-semibold">Sign in</a></div>
        <!--end::Sign up-->
      </form>
	    <div *ngIf="!registrationEnabled" class="form w-100">
		    <div class="text-center mb-11">
			    <h1 class="text-dark fw-bolder mb-3">Sign Up</h1>
			    <h3 class="mt-4">Registration Temporarily Closed</h3>
			    <p>Our app is undergoing an exciting relaunch! Registration is temporarily closed during this process. We appreciate your understanding and look forward to welcoming you soon. Please check back for updates!</p>
		    </div>
		    <a class="btn btn-outline w-100" [routerLink]="['/sign-in']">Back to Sign In</a>
	    </div>
      <!--end::Form-->
    </div>

 <ng-template #loadingBlock>
   <span>Please wait...
										<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
 </ng-template>
<!--<h2 class="text-center my-3">Sign Up</h2>-->

<!--<form class="w-100 my-3" [formGroup]="form">-->
<!--  <div class="form-group">-->
<!--    <div class="input-group mb-2">-->
<!--      <div class="input-group-prepend">-->
<!--        <div class="input-group-text"><img src="./assets/images/lock.png" alt="" class="img-responsive"></div>-->
<!--      </div>-->
<!--      <input formControlName="name" type="text" class="form-control" id="loginNameGroup" placeholder="Your name">-->
<!--    </div>-->
<!--    <mat-error *ngIf="name?.hasError('minlength')">-->
<!--      Min length of name <strong>2</strong> symbols-->
<!--    </mat-error>-->
<!--  </div>-->
<!--  <div class="form-group">-->
<!--    <div class="input-group mb-2">-->
<!--      <div class="input-group-prepend">-->
<!--        <div class="input-group-text"><img src="./assets/images/user.png" alt="" class="img-responsive"></div>-->
<!--      </div>-->
<!--      <input formControlName="email" type="email" class="form-control" id="loginEmailGroup" placeholder="Email">-->
<!--    </div>-->
<!--    <mat-error *ngIf="email?.hasError('email') && !email?.hasError('required')">-->
<!--      Please enter a valid email address-->
<!--    </mat-error>-->
<!--    <mat-error *ngIf="email?.hasError('required') && email?.touched">-->
<!--      Email is <strong>required</strong>-->
<!--    </mat-error>-->
<!--  </div>-->
<!--  <div class="form-group">-->
<!--    <div class="input-group mb-2">-->
<!--      <div class="input-group-prepend">-->
<!--        <div class="input-group-text"><img src="./assets/images/lock.png" alt="" class="img-responsive"></div>-->
<!--      </div>-->
<!--      <input formControlName="password" type="password" class="form-control" id="loginPasswordGroup" placeholder="Create password (8+ characters)">-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="form-group">-->
<!--    <div class="input-group mb-2">-->
<!--      <div class="input-group-prepend">-->
<!--        <div class="input-group-text"><img src="./assets/images/lock.png" alt="" class="img-responsive"></div>-->
<!--      </div>-->
<!--      <input formControlName="passwordConfirm" type="password" class="form-control" id="loginPasswordConfirmGroup" placeholder="Repeat a password">-->
<!--    </div>-->
<!--  </div>-->
<!--&lt;!&ndash;  <div class="form-group" *ngIf="referralID">&ndash;&gt;-->
<!--&lt;!&ndash;    <div class="input-group mb-2">&ndash;&gt;-->
<!--&lt;!&ndash;      <div class="input-group-prepend">&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="input-group-text"><img src="./assets/images/auth-gift.png" alt="" class="img-responsive"></div>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;      <input formControlName="coupon" type="text" class="form-control" id="couponGroup" placeholder="Coupon Code">&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;  </div>&ndash;&gt;-->
<!--  <div *ngIf="referralID">-->
<!--    <div class="alert alert-success text-center mb-2" role="alert" >-->
<!--      <img src="./assets/images/auth-gift.png" class="icon-img mb-1 mr-1" alt=""> Referral link bonus<br/>-->
<!--      $5 will be added to your account-->
<!--    </div>-->
<!--&lt;!&ndash;    <button type="button" class="btn btn-link w-100" (click)="showCouponForm()">I want to use coupon code instead</button>&ndash;&gt;-->
<!--  </div>-->
<!--  -->
<!--  -->
<!--  -->
<!--  &lt;!&ndash;		<div class="form-group form-check">&ndash;&gt;-->
<!--  &lt;!&ndash;			<input type="checkbox" class="form-check-input" id="exampleCheck1">&ndash;&gt;-->
<!--  &lt;!&ndash;			<label class="form-check-label" for="exampleCheck1">Check me out</label>&ndash;&gt;-->
<!--  &lt;!&ndash;		</div>&ndash;&gt;-->
<!--  <button type="submit" class="btn btn-default w-100  " [disabled]="form.invalid || (isLoading | async)" (click)="captchaRef.execute()"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="(isLoading | async)"></span> Sign Up</button>-->
<!--</form>-->
<!--<div class="d-flex flex-column align-items-center">-->
<!--  <div class="both-lined"><p>or use</p></div>-->
<!--  <div class="social-container">-->
<!--    <a (click)="goToThirdParty('google')" class="social"><img src="./assets/images/google.svg" [width]="16"-->
<!--                                                              alt="Google" title="Google"></a>-->
<!--&lt;!&ndash;    <a (click)="goToThirdParty('github')" class="social"><img src="./assets/images/github.svg" [width]="16"&ndash;&gt;-->
<!--&lt;!&ndash;                                                              alt="GitHub" title="GitHub"></a>&ndash;&gt;-->
<!--  </div>-->
<!--</div>-->
<!--<p class="text-center">Already have an account? <a routerLink="/sign-in">Log in</a></p>-->

<!--<re-captcha size="invisible" #captchaRef (resolved)="$event && registerUser($event)"-->
<!--            [siteKey]="SITE_ID_NG">-->
<!--</re-captcha>-->
