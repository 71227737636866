import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {signOut} from "../store/user/user.actions";
import {throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {AppState} from "../store/app.reducer";
import {Store} from "@ngrx/store";
import {MatSnackBar} from "@angular/material/snack-bar";
import {setErrorBalanceData, updateBalanceData} from "../store/balance/balance.actions";
import {CookieService} from "./cookie.service";
import {ActivatedRoute, Router} from "@angular/router";
import {isDemo} from "../store/user/user.selector";
import {getDomainFromTarget} from "../utils/utils";

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
  
  isDemo: boolean = false;
  
  constructor(private store: Store<AppState>, private _snackBar: MatSnackBar, private router: Router, private cookieService: CookieService) {
    this.store.select(isDemo).subscribe(data => this.isDemo = data)
  }
  
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.includes('backlinks_api')) {
      req = req.clone({url: req.url.replace('backlinks_api', `${environment.apiUrl}`)});
      if(environment.production) {
        req = req.clone({withCredentials: true});
      }
      
    }
    
    return next.handle(this.prepareRequest(req))
    .pipe(
        map(response => {
          
          if (response instanceof HttpResponse) {
            const newToken = response.headers.get('Authorization')?.split('Bearer ')[1];
            if (newToken)
              localStorage.setItem('token', newToken)
  
            this.catchHeaders(response);
            
            if (response.body?.data?.errors) {
              response.body.data.errors.forEach((error: {[key: string]: string}) => {
                let snakBarRef;
                if (error && 'internalErrorMessage' in error) {
                  if (this.checkBigUrl(req.body.target)) {
                    snakBarRef = this._snackBar.open('The target domain you\'ve entered contains an excessive amount of data, which cannot be loaded. Please select a different target domain. Thank you!', 'Close', {
                      duration: 10000,
                      panelClass: ['snack-bar-error']
                    } )
                  } else {
                    snakBarRef = this._snackBar.open('Something went wrong. Please try again', 'Close', {
                      duration: 5000,
                      panelClass: ['snack-bar-error']
                    } )
                  }
                  
                }
              })
            }
            
            //
            // const sessionId = this.cookieService.getCookie('LISESSID');
            // console.log('sessionId', sessionId);
            //
            // const sessionHeader = response.headers.get('Set-Cookie');
            // console.log('sessionHeader', sessionHeader);
            // console.log('sessionHeader', sessionHeader?.split(';')[0]);
            // console.log('sessionHeader', sessionHeader?.split(';')[0].split('LISESSID=')[1]);
          }

          return response
        }),
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            switch ((<HttpErrorResponse>error).status) {
              case 401:
                return this.handle401Error(error);
              case 402:
                return this.handle402Error(error);
              case 416:
                return this.handle416Error(error);
              default:
                return throwError(error)
            }
          } else {
            return throwError(error);
          }
        })
    );
  }
  
  private prepareRequest(req: HttpRequest<any>): HttpRequest<any> {
  
    this.store.dispatch(setErrorBalanceData({errors: null}));
    return this.addToken(req);
  }
  
  private addToken(req: HttpRequest<any>): HttpRequest<any> {
    
    const token = localStorage.getItem('token');
    
    return req.clone({setHeaders: {Authorization: 'Bearer ' + token}});
  }
  
  private catchHeaders(response: HttpResponse<any>) {
    if(response.headers.has('Domains-Limit') || response.headers.has('Domains-Used') || response.headers.has('Rows-Limit') || response.headers.has('Rows-Used')) {
      const balanceData = {
        domains_limit: response.headers.get('Domains-Limit'),
        domains_used: response.headers.get('Domains-Used'),
        rows_per_report_limit: null,
        rows_per_month_limit: response.headers.get('Rows-Limit'),
        rows_per_month_used: response.headers.get('Rows-Used')
      };
      
      this.store.dispatch(updateBalanceData(balanceData))
      
      // Test
      // this.store.dispatch(setErrorBalanceData({errors: {domains_reached: true}}))
    }
  }
  
  private handle401Error(error: HttpErrorResponse) {
    this.store.dispatch(signOut());
    return throwError(error)
  }
  
  private handle402Error(error: HttpErrorResponse) {
    if (error.error.message) {
      this.showSnackBarError(error.error.message)
    }
    if (this.isDemo) {
      this.router.navigate(['/select-plans']);
    } else {
      this.router.navigate(['/plans']);
    }
    return throwError(error)
  }
  
  private handle416Error(error: HttpErrorResponse) {
    console.log('error', error);
    
    let message = error.error.message ?? '';
    if(error.headers.has('Rows-Report-Limit') && error.headers.has('Rows-Requested')) {
      message += ' Requested ' + error.headers.get('Rows-Requested') + ' of ' + error.headers.get('Rows-Report-Limit') + ' according to rows plan limit';
      this.store.dispatch(setErrorBalanceData({errors: {rows_per_report_reached: true}}))
    } else if (error.headers.has('Rows-Limit') && error.headers.has('Rows-Used')) {
      message += ' Used ' + error.headers.get('Rows-Used') + ' of ' + error.headers.get('Rows-Limit') + ' according to rows per month plan limit';
      this.store.dispatch(setErrorBalanceData({errors: {rows_per_month_reached: true}}))
    } else if (error.headers.has('Domains-Limit') && error.headers.has('Domains-Used')) {
      message += ' Checked ' + error.headers.get('Domains-Used') + ' of ' + error.headers.get('Domains-Limit') + ' according to domains per month plan limit';
      this.store.dispatch(setErrorBalanceData({errors: {domains_reached: true}}))
    }
    
    return throwError(error)
  }
  
  private showSnackBarError(message: string) {
    this._snackBar.open(message, 'X', {
      duration: 15000,
      panelClass: ['snack-bar-error']
    });
  }
  
  private checkBigUrl(target: string) {
    if (!target) return false;
    const hostname: string = getDomainFromTarget(target);
    const bigDomains: string[] = [
      'facebook.com',
      'twitter.com',
      'google.com',
      'instagram.com',
      'youtube.com',
      'blogger.com',
      'linkedin.com',
      'pinterest.com',
      'sidex.ru',
      'casatemporada.com',
      'miit.gov.cn',
      'baidu.com',
      'whatsapp.com',
      't.me',
      'apple.com',
      'stripchat.com',
      'qq.com',
      'addtoany.com',
      'vk.com',
      'wordpress.com',
      'pricebar.ru',
      'sexhd.pics',
      'bp.blogspot.com',
      'desktopnexus.com',
      'tumblr.com',
      'wikipedia.org',
      'reddit.com',
      'gdom.org',
      'wa.me',
      'adz.app.br',
      'wordpress.org',
      'yandex.ru',
      'manualsonline.com',
      'github.com',
      'studentus.pro',
      'weibo.com',
      'fappedia.com',
      'tiktok.com',
      'xn----8sbn6afubnnd.xn--p1ai',
      'jjgirls.com',
      'bit.ly',
      'beian.gov.cn',
      'webcamgalore.com',
      'amazon.com',
      'afrikrea.com',
      'wp.com',
      'doi.org',
      'taoche.com',
      'rakuten.co.jp',
      'telegram.me',
      'azg168.cn',
      'thefreedictionary.com',
      'ok.ru',
      'airbnb.com',
      'fc2.com',
      'fang.com',
      'xn--80aao6anggb.xn--p1ai',
      'ipt.pw',
      'line.me',
      'goo.gl',
    ]
    return bigDomains.includes(hostname);
  }
}
