import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {StoreModule} from "@ngrx/store";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {environment} from "../environments/environment";
import {reducers} from "./core/store/app.reducer";
import {HeaderModule} from "./core/modules/header/header.module";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LeftMenuModule} from "./core/modules/left-menu/left-menu.module";
import {EffectsModule} from "@ngrx/effects";
import {UserEffects} from "./core/store/user/user.effect";
import {AuthModule} from "./auth/auth.module";
import {AuthGuard} from "./auth/guards/auth.guard";
import {VerifyGuard} from "./auth/guards/verify.guard";
import {MAT_RADIO_DEFAULT_OPTIONS} from "@angular/material/radio";
import {AccountModule} from "./account/account.module";
import {BalanceEffect} from "./core/store/balance/balance.effect";
import {OverviewApi} from "./products/overview/api/overview.api";
import {DomainEffect} from "./core/store/domain/domain.effect";
import {CurrentDomainGuard} from "./auth/guards/current-domain.guard";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
        BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HeaderModule,
    LeftMenuModule,
    AuthModule,
    AccountModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([UserEffects, BalanceEffect, DomainEffect, CurrentDomainGuard]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
  ],
  providers: [AuthGuard, VerifyGuard, OverviewApi, {
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
