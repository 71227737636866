import {Action, createReducer, on} from "@ngrx/store";
import {setDomain, setDomainData, setDomainTypeId} from "./domain.actions";

export interface DomainState {
  domain: string;
  domainTypeId: number;
}

const initialState: DomainState = {
  domain: '',
  domainTypeId: 0
}

const _domainReducer = createReducer(
  initialState,
  on(setDomainData, (state, domainData: {domain: string; domainTypeId: number;}) => {
    if (state.domain === domainData.domain && state.domainTypeId === domainData.domainTypeId) {
      return state
    }
    return state = domainData
  }),
  on(setDomain, (state, data: {domain: string}) => state = {...state, domain: data.domain}),
  on(setDomainTypeId, (state, data: {domainTypeId: number}) => state = {...state, domainTypeId: data.domainTypeId})
);

export function domainReducer(state: DomainState | undefined, action: Action) {
  return _domainReducer(state, action)
}
