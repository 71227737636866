import {Injectable} from '@angular/core';
import {CookieService} from "./cookie.service";

@Injectable({
	providedIn: 'root'
})
export class GaService {
	
	gaInstance: any;
	
	constructor(private cookieService: CookieService) {
	}
	
	initGa(){
		if ((window as any).ga && (window as any).ga.getAll) {
			this.gaInstance = (window as any).ga;
		}
	}
	
	getGaCid(){
		if(!this.gaInstance) this.initGa();
		
		let ga_cid;
		if(this.gaInstance){
			const tracker = this.gaInstance.getAll()[0];
			ga_cid = tracker.get('clientId');
			console.log('GaService 1 - ga_cid: ', ga_cid);
		} else {
			console.log('no ga');
		}
		
		if(!ga_cid){
			const _ga = this.cookieService.getCookie('_ga');
			ga_cid = _ga.split('.').slice(-2).join(".");
			console.log('GaService 2 - ga_cid: ', ga_cid);
		}
		
		return ga_cid;
	}
	
	gaRegisterSuccess(){
		if(!this.gaInstance) this.initGa();
		
		if(this.gaInstance){
			this.gaInstance('send', 'event', 'Form','Submit');
		} else {
			console.log('no ga');
		}
		
		console.log('send gaRegisterSuccess');
	}
	
	gaAuthSuccess(userId: number, firstLogin: boolean = false){
		if(!this.gaInstance) this.initGa();
		
		if(this.gaInstance) {
			this.gaInstance('set', 'userId', userId);
			this.gaInstance('send', 'event', 'authentication', 'user-id available');
		} else {
			console.log('no ga');
		}
		
		if(firstLogin)
			this.setFirstVisitScript();
		console.log('send gaAuthSuccess');
	}
	
	setFirstVisitScript() {
		const scriptElement = document.createElement('script');
		scriptElement.id = 'first-visit-script';
		scriptElement.innerHTML = `window.dataLayer = window.dataLayer || [ ]; dataLayer.push({'event': 'firstLogin'});`;
		document.getElementsByTagName('body')[0].appendChild(scriptElement);
	}
	
}
