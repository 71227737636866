import {createAction, props} from "@ngrx/store";

export const setDomainData = createAction(
  '[Set domain data]',
  props<{domain: string; domainTypeId: number;}>()
);

export const setDomain = createAction(
  '[Set domain]',
  props<{domain: string}>()
);

export const setDomainTypeId = createAction(
  '[Set domain type id]',
  props<{domainTypeId: number}>()
)
