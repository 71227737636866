import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {
    authenticate,
    authenticateError,
    authenticateSuccess,
    signOut,
    signOutSuccess,
    signUp,
    signUpError,
    signUpSuccess,
    sendEmailAction,
    sendEmailActionSuccess,
    sendEmailActionError,
    sendNewPassAction,
    sendNewPassActionSuccess,
    sendNewPassActionError,
    verifyUserAction,
    verifyUserActionSuccess,
    verifyUserActionError,
    authenticateOneTime,
    updateUserInfo,
    updateUserInfoSuccess,
    updateUserInfoError,
    authenticateThirdParty,
    authenticateThirdPartySuccess,
    updateUserTariff,
    updateUserTariffSuccess
} from "./user.actions";
import {catchError, exhaustMap, map, tap, timeout} from "rxjs/operators";
import {of} from "rxjs";
import {User} from "../../models/user.model";
import {AuthApi} from "../../../auth/api/auth.api";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {secondsToTimeString, transformHttpError} from "../../utils/utils";
import {MatDialog} from "@angular/material/dialog";
import {SuccessVerifyComponent} from "../../../auth/containers/sign-in/success-verify/success-verify-component.component";
import {ErrorVerifyComponent} from "../../../auth/containers/sign-in/error-verify/error-verify-component.component";
import {AccountApi} from "../../../account/api/account.api";
import {GaService} from "../../services/ga.service";
import {CookieService} from "../../services/cookie.service";
import {UpdateUserDataModel} from "../../../account/models/update-user-data.model";

@Injectable()
export class UserEffects {

  private clearLocalStorage(): void {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('last_requested_url');
  }

  private setUserToLocalStorage(token: string, user: User): void {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
  }

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticate),
      exhaustMap((actions) => this.authApi.userLogin(actions.email.toLowerCase(), actions.password, actions.recaptcha)
        .pipe(
          map(userData => {
            // update user "limits"
            // maybe routing somewhere
            let navigateRoute = '/overview';
            const lastReqUrl = localStorage.getItem('last_requested_url')
             if (localStorage.getItem('last_requested_url') && lastReqUrl) {
                 const lastUrlData = JSON.parse(lastReqUrl.trim());
                 localStorage.removeItem('last_requested_url');
                 const lastUrl = lastUrlData?.url;
                 const lastUrlTime = lastUrlData?.time;
                 if (lastUrl && lastUrlTime && (Date.now() - lastUrlTime) < (60 * 60 * 6)) {
                     navigateRoute = lastUrl
                 }
             }
            // this.setUserToLocalStorage(userData.data.token, userData.data.user);

            // this.gaService.gaAuthSuccess(userData.data.user.id, userData.data.user.first_visit);
              if (!userData.data.user.tariff_id) {
                  this.router.navigate(['/select-plans']);
              } else {
                  this.router.navigate([navigateRoute]);
              }
            // this.router.navigate([navigateRoute])
            return authenticateSuccess({...userData.data});
          }),
          catchError((error) => {
              if (error.error.errors) {
                  this.showSnackBarError(transformHttpError(error.error.errors));
              } else {
                  this.showSnackBarError(error.error.message);
              }
            return of(authenticateError(error));
          })
        )
      )
    )
  );
  
  authSuccess$ = createEffect(() =>
      this.actions$.pipe(
          ofType(authenticateSuccess),
          tap((data) => {
              this.setUserToLocalStorage(data.token, data.user);
          })
      ), { dispatch: false })
    
    loginByThirdParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authenticateThirdParty),
            exhaustMap(payload => {
                const referrerParams = this.cookieService.getAllReferrerParams();

                return this.authApi.userLoginByThirdParty(
                    payload.provider, payload.code, payload.scope, payload.authuser, payload.session_state, payload.prompt, payload.affiliate_id, payload.ga_cid, referrerParams
                )
                .pipe(
                    map(userData => {
                        // this.setUserToLocalStorage(userData.data.token, userData.data.user);
                        // console.log(userData)
                        this.clearRegisterCookies();
                        // this.router.navigate(['/dashboard'])
                        return authenticateThirdPartySuccess({...userData.data});
                    }),
                    catchError((error) => {
                        if (error.error.errors) {
                            this.showSnackBarError(transformHttpError(error.error.errors));
                        } else {
                            this.showSnackBarError(error.error.message);
                        }
                        this.router.navigate(['/sign-in'])
                        return of(authenticateError(error));
                    })
                )
}
            )
        )
    );
    
    loginByThirdPartySuccess$ = createEffect( () =>
        this.actions$.pipe(
            ofType(authenticateThirdPartySuccess),
            tap(() => {
                localStorage.removeItem('referralID');
                this.router.navigate(['/overview'])
            })
        ),
        { dispatch: false }
    )
  
  loginOneTime$ = createEffect( () =>
    this.actions$.pipe(
        ofType(authenticateOneTime),
        exhaustMap((actions) => this.authApi.loginOneTime(actions.uid, actions.onetime_pass)
            .pipe(
                map(userData => {
                    this.setUserToLocalStorage(userData.token, userData.user);
                    
                    this.router.navigate(['/overview'])
                    return authenticateSuccess({...userData});
                }),
                catchError((error) => {
                    if (error.error.errors) {
                        this.showSnackBarError(transformHttpError(error.error.errors));
                    } else {
                        this.showSnackBarError(error.error.message);
                    }
                    this.router.navigate(['/sign-in'])
                    return of(authenticateError(error));
                })
            )
        )
    )
  )

  logOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signOut),
      map(() => {
        // update client limits
        // maybe routing somewhere
        this.authApi.logout();
        this.clearLocalStorage();
        this.router.navigate(['/auth']);
        
        return signOutSuccess();
      })
    )
  )

  signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signUp),
      exhaustMap(actions => {
        const referralID = localStorage.getItem('referralID') || undefined;
        const referrerParams = this.cookieService.getAllReferrerParams();
        console.log('r', referrerParams);
    
          // this.authApi.userRegister(actions.name, actions.email.toLowerCase(), actions.password, actions.recaptcha, referralID, actions.ga_cid, referrerParams)

        return this.authApi.userRegister(actions.name, actions.email.toLowerCase(), actions.password, actions.password_confirmation, actions.recaptcha)
            .pipe(
              map((userData: any) => {
                  this.clearRegisterCookies();
    
                  this.router.navigate(['/sign-in']);
                  this._snackBar.open(userData.msg, 'Close', {panelClass: ['snack-bar-success']});
                  return signUpSuccess({...userData.data});
              }),
            catchError(error => {
                if (error.error.errors) {
                    this.showSnackBarError(transformHttpError(error.error.errors));
                } else {
                    let message = error.error.message;
                    if(error.status === 429 && error.headers.has('Retry-After') && parseInt(error.headers.get('Retry-After')?? '', 10) > 0) {
                        const retrySeconds = parseInt(error.headers.get('Retry-After')?? '', 10);
                        message += ' Retry in ' + secondsToTimeString(retrySeconds);
                    }
                    this.showSnackBarError(message);
                }
                return of(signUpError(error));
            })
            )
        }
      )
    )
  );

  sendEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendEmailAction),
      exhaustMap(actions => this.authApi.sendEmailForReset(actions.emailForReset, actions.recaptcha)
        .pipe(
          map((email: any) => {
            this._snackBar.open(email.text, 'Close', {duration: 5000, panelClass: ['snack-bar-success']});
            return sendEmailActionSuccess(email);
          }),
          catchError(error => {
              if (error.error.errors) {
                  this._snackBar.open(transformHttpError(error.error.errors), 'Close', {duration: 5000, panelClass: ['snack-bar-error']});
              } else {
                  this._snackBar.open(error.error.message, 'Close', {duration: 5000, panelClass: ['snack-bar-error']});
              }
            return of(sendEmailActionError(error));
          })
        )
      )
    )
  );

  sendNewPass$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendNewPassAction),
      exhaustMap(actions => this.authApi.sendNewPass(actions.newPassData.user_id, actions.newPassData.token, actions.newPassData.password, actions.newPassData.passwordConfirm, actions.recaptcha)
      .pipe(
        map((passData: any) => {
          this._snackBar.open(passData.text, 'Close', {duration: 5000, panelClass: ['snack-bar-success']});
          this.router.navigate(['/sign-in']);
          return sendNewPassActionSuccess(passData);
        }),
        catchError(error => {
          this._snackBar.open(error.error.message, 'Close', {duration: 5000, panelClass: ['snack-bar-error']});
          return of(sendNewPassActionError(error));
        })
      )
    )
  ));

  verifyUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(verifyUserAction),
      exhaustMap(actions => this.authApi.verifyUser(actions.user_id, actions.activation_code)
      .pipe(
        map((verifyData: any) => {
            if (verifyData.link_to) {
                this.router.navigate([`/${verifyData.link_to}`]);
            } else {
                this.router.navigate(['/sign-in'])
            }
            this._snackBar.open('You\'ve been succesfully verified', 'Close', {duration: 5000, panelClass: ['snack-bar-success']});
            
          return verifyUserActionSuccess(verifyData);
        }),
        catchError(error => {
            this.router.navigate(['/sign-in'])
            let errorMessage = 'Email verification failed. Please contact our support team.';
            if (error.error.errors.user_id) {
                errorMessage = error.error.errors.user_id
            }
            this._snackBar.open(errorMessage, 'Close', {duration: 5000, panelClass: ['snack-bar-error']});
          return of(verifyUserActionError(error));
        })
      ))
    )
  )
  
  verifyUserActionSuccess$ = createEffect( () =>
      this.actions$.pipe(
        ofType(verifyUserActionSuccess),
        tap(() => {
          const scriptElement = document.createElement('script');
          scriptElement.innerHTML = `window.dataLayer = window.dataLayer || []; dataLayer.push({'event': 'sign_up'});`;
          document.getElementsByTagName('body')[0].appendChild(scriptElement);
        })
      ),
    { dispatch: false }
  )
    
    updateUserTariff$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateUserTariff),
            map(tariffData => {
                let user = JSON.parse(<string>localStorage.getItem('user'));
                user.tariff_id = tariffData.tariff_id
                return updateUserTariffSuccess({user})
            })
        ))
    
    updateUserTariffSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateUserTariffSuccess),
            tap((data) => {
                localStorage.setItem('user', JSON.stringify(data.user));
                this.router.navigate(['overview']);
            })
        ), { dispatch: false })
   
   updateSettings$ = createEffect(() =>
       this.actions$.pipe(
           ofType(updateUserInfo),
           exhaustMap((actions: UpdateUserDataModel) => this.accountApi.updateUser(actions)
               .pipe(
                   map(userData => {
                       localStorage.setItem('user', JSON.stringify(userData.data.user));
                       this._snackBar.open('User Successfully updated', 'Close', {duration: 5000, panelClass: ['snack-bar-success']});
                       return updateUserInfoSuccess({...userData.data});
                   }),
                   catchError((error) => {
                       if (error.error.errors) {
                           this._snackBar.open(transformHttpError(error.error.errors), 'Close', {duration: 2000, panelClass: ['snack-bar-error']})
                       } else {
                           this._snackBar.open(transformHttpError(error.error.message), 'Close', {duration: 2000, panelClass: ['snack-bar-error']})
                       }
                       return of(updateUserInfoError(error));
                   })
               )
           )
       )
   )

  constructor(
    private actions$: Actions,
    private authApi: AuthApi,
    // private accountApi: AccountApi,
    private router: Router,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private gaService: GaService,
    private cookieService: CookieService,
    private accountApi: AccountApi
  ) {
  }

  private showSnackBarError(message: string) {
    this._snackBar.open(message, 'X', {
      duration: 2000,
      panelClass: ['snack-bar-error']
    });
  }

  private clearRegisterCookies() {
      localStorage.removeItem('referralID');

      this.cookieService.deleteCookie('referrer_register');
        this.cookieService.deleteCookie('referrer');
        for (let i = 1; i < 6; i++) {
            this.cookieService.deleteCookie('referrer_params_' + i);
        }
        this.cookieService.deleteCookie('aff_id');
    }
}
