import {createAction, props} from '@ngrx/store';
import {User} from "../../models/user.model";
import {ResetPass} from "../../../auth/models/reset-pass.model"
import {RegisterFormModel, RegisterPostData} from "../../../auth/models/register-form.model";
import {UpdateUserDataModel} from "../../../account/models/update-user-data.model";


export const authenticate = createAction(
  '[User authenticate]',
  props<{email: string, password: string, recaptcha: string}>()
);

export const authenticateOneTime = createAction(
    '[User authenticate by onetime pass]',
    props<{uid: string, onetime_pass: string}>()
);

export const authenticateThirdParty = createAction(
    '[User authenticate by social]',
    props<{provider: string, code: string, scope: string, authuser: string, session_state: string, prompt: string, affiliate_id: string | null, ga_cid: string | null}>()
);

export const authenticateSuccess = createAction(
  '[User authenticate success]',
  props<{token: string, user: User}>()
);

export const authenticateThirdPartySuccess = createAction(
    '[User social authenticate success]',
    props<{token: string, user: User}>()
);

export const authenticateError = createAction(
  '[User authenticate error]',
  props<{message: string}>()
);

export const signOut = createAction('[User sign out]');

export const signOutSuccess = createAction('[User sign out success]');

export const signOutError = createAction('[User sign out error]');

export const signUp = createAction(
  '[User sign up]',
  props<RegisterPostData>()
  );

export const signUpSuccess = createAction(
  '[User sign up success]',
  props<{token: string, user: User}>()
);

export const signUpError = createAction(
  '[User sign up error]',
  props<{message: string}>()
);

export const sendEmailAction = createAction(
  '[User send email]',
  props<{emailForReset: string, recaptcha: string}>()
);

export const sendEmailActionSuccess = createAction(
  '[User send email success]',
  props<{data: string}>()
);

export const sendEmailActionError = createAction(
  '[User send email error]',
  props<{message: string}>()
);

export const sendNewPassAction = createAction(
  '[User send new password]',
  props<{newPassData: ResetPass, recaptcha: string}>()
);

export const sendNewPassActionSuccess = createAction(
  '[User send new password success]',
  props<{data: any}>()
);

export const sendNewPassActionError = createAction(
  '[User send new password error]',
  props<{message: string}>()
);

export const verifyUserAction = createAction(
  '[Verify user]',
  props<{user_id: string, activation_code: string}>()
)

export const verifyUserActionSuccess = createAction(
  '[Verify user success]',
  props<{data: any}>()
)

export const verifyUserActionError = createAction(
  '[Verify user error]',
  props<{data: any}>()
)

export const updateUserInfo = createAction(
    '[Update User Info]',
    props<UpdateUserDataModel>()
)


export const updateUserInfoSuccess = createAction(
    '[Update User Info Success]',
    props<{user: User}>()
)

export const updateUserInfoError = createAction(
    '[Update User Info Error]',
    props<{message: string}>()
)

export const updateUserTariff = createAction(
    '[Update User Tariff]',
    props<{tariff_id: number}>()
)

export const updateUserTariffSuccess = createAction(
    '[Update User Tariff Success]',
    props<{user: User}>()
)

export const updateUserTariffError = createAction(
    '[Update User Tariff Error]',
    props<{message: string}>()
)

export const activateDemo = createAction('[User activate demo]');
