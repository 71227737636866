import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {ErrorVerifyComponent} from './containers/sign-in/error-verify/error-verify-component.component';
import {SuccessVerifyComponent} from './containers/sign-in/success-verify/success-verify-component.component'

import {containers} from "./containers";
import {AuthFacade} from "./auth.facade";
import {RequestInterceptorService} from "../core/services/app.interceptor";
import {AuthRoutingModule} from "./auth-routing.module";
import {RECAPTCHA_V3_SITE_KEY, RecaptchaModule, ReCaptchaV3Service} from 'ng-recaptcha';
import {environment} from "../../environments/environment";
import {AuthApi} from "./api/auth.api";



@NgModule({
  declarations: [
    ...containers,
    ErrorVerifyComponent,
    SuccessVerifyComponent
    // EmailConfirmComponent,
  ],
	imports: [CommonModule, HttpClientModule, ReactiveFormsModule, AuthRoutingModule, MatInputModule, MatButtonModule, MatSnackBarModule, MatDialogModule, RecaptchaModule],
  providers: [
    AuthApi,
    AuthFacade,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    },
    ReCaptchaV3Service,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaKey
    },
  ]
})
export class AuthModule { }
