<div class="container-fluid">
	<div class="card mb-5 mb-xl-10">
		<div class="card-header border-0 cursor-pointer" role="button" aria-expanded="true">
			<div class="card-title m-0">
				<h3 class="fw-bold m-0">Google Connection</h3>
			</div>
		</div>
		<div class="card-body border-top p-9">
			<div *ngIf="userSocialData">
				<div>Connected to <strong>{{userSocialData.email}}</strong> </div>
				<div class="d-flex align-items-center mb-2 mt-2">
					<h3 class="mr-2 mb-0">Your Projects</h3> <button type="button" class="btn btn-icon" (click)="loadProjects('hard')" matTooltip="Synchronize data from google"><span class="fa fa-refresh"></span></button>
				</div>
				<ul class="list-group" *ngIf="!projectLoading; else projectLoadingBlock">
					<li *ngFor="let project of projects" class="list-group-item">
						{{project}}
					</li>
				</ul>
				<div *ngIf="!projectLoading && !projects.length">
					No projects found.
				</div>
				<button class="btn btn-danger btn-sm mt-2" (click)="revokeAccount()" [disabled]="loading">
					<span class="indicator-label" *ngIf="!loading; else loadingBlock">Revoke Account</span>
				</button>
			</div>
			<div *ngIf="!userSocialData">
				<button class="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap" (click)="connectAccount()" [disabled]="loading">
					<img alt="Logo" src="assets/images/brand-logos/google-icon.svg" class="h-15px me-3" /><span class="indicator-label" *ngIf="!loading; else loadingBlock">Connect Account</span></button>
			</div>
		</div>
	</div>
</div>

<ng-template #loadingBlock>
	<span>Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
</ng-template>

<ng-template #projectLoadingBlock>
	<div>Loading your project... <span class="spinner-border spinner-border-sm align-middle ms-2"></span></div>
</ng-template>
