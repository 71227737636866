<section class="left-menu">
  <div [@enter] class="nk-sidebar nk-sidebar-fixed is-light" [class.shown]="isShown" data-content="sidebarMenu">
    <div class="nk-sidebar-element nk-sidebar-head">
      <div class="nk-sidebar-brand">
        <a routerLink="overview" (click)="menuItemClickHandler()" class="logo-link nk-sidebar-logo">
          <img class="logo-dark logo-img" src="./assets/images/logo-linksinsider.png" alt="logo-dark">
        </a>
      </div>
    </div>
    <div class="nk-sidebar-element">
      <div class="nk-sidebar-content">
        <div class="nk-sidebar-menu">
          <ul class="nk-menu">
            <li [class.active]="router.url.includes('overview')" class="nk-menu-item current-page">
              <a routerLink="overview" class="nk-menu-link" title="Overview">
                <i class="li-icon li-icon-20 li-icon-overview"></i><span class="nk-menu-text">Overview</span></a>
            </li>
            <li [class.active]="router.url.includes('backlinks')" class="nk-menu-item has-sub">
              <a routerLink="backlinks" class="nk-menu-link nk-menu-toggle" title="Backlinks">
                <i class="li-icon li-icon-20 li-icon-backlinks"></i><span class="nk-menu-text">Backlinks</span>
              </a>
              <ul class="nk-menu-sub" [ngStyle]="{'display': router.url.includes('backlinks') ? 'block' : 'none'}">
                <li class="nk-menu-item">
                  <a routerLink="backlinks/new" class="nk-menu-link" title="New" [class.active]="router.url.includes('backlinks/new')">
                    <i class="li-icon li-icon-20 li-icon-new-backlinks"></i><span class="nk-menu-text">New</span>
                  </a>
                </li>
                <li class="nk-menu-item">
                  <a routerLink="backlinks/lost" class="nk-menu-link" title="Lost" [class.active]="router.url.includes('backlinks/lost')">
                    <i class="li-icon li-icon-20 li-icon-lost-backlinks"></i> <span class="nk-menu-text">Lost</span>
                  </a>
                </li>
                <li class="nk-menu-item">
                  <a routerLink="backlinks/broken" class="nk-menu-link" title="Broken" [class.active]="router.url.includes('backlinks/broken')">
                    <i class="li-icon li-icon-20 li-icon-broken-backlinks"></i><span class="nk-menu-text">Broken</span>
                  </a>
                </li>
              </ul>
            </li>
            <li [class.active]="router.url.includes('top-pages')" class="nk-menu-item">
              <a routerLink="top-pages" class="nk-menu-link" title="Top Pages">
              <i class="li-icon li-icon-20 li-icon-top-pages"></i><span class="nk-menu-text">Top Pages</span></a>
            </li>
            <li [class.active]="router.url.includes('referring-domains')" class="nk-menu-item">
              <a routerLink="referring-domains" class="nk-menu-link" title="Referring domains">
              <i class="li-icon li-icon-20 li-icon-ref-domains"></i><span class="nk-menu-text">Referring Domains</span></a>
            </li>
            <li [class.active]="router.url.includes('anchors')" class="nk-menu-item">
              <a routerLink="anchors" class="nk-menu-link" title="Anchors">
              <i class="li-icon li-icon-20 li-icon-anchor"></i><span class="nk-menu-text">Anchors</span></a>
            </li>
            <li [class.active]="router.url.includes('link-competitors')" class="nk-menu-item">
              <a routerLink="link-competitors" class="nk-menu-link" title="Link Competitors">
                <i class="li-icon li-icon-20 li-icon-competitors"></i><span class="nk-menu-text">Link Competitors</span></a>
            </li>
            <li [class.active]="router.url.includes('link-gap')" class="nk-menu-item">
              <a routerLink="link-gap" class="nk-menu-link" title="Link gap">
              <i class="li-icon li-icon-20 li-icon-link-gap"></i><span class="nk-menu-text">Link Gap</span></a>
            </li>
            <li [class.active]="router.url.includes('referring-networks')" class="nk-menu-item">
              <a routerLink="referring-networks" class="nk-menu-link" title="Referring networks">
                <i class="li-icon li-icon-20 li-icon-ref-networks"></i><span class="nk-menu-text">Referring Networks</span></a>
            </li>
<!--            <li [class.active]="router.url.includes('link-opportunities')" class="nk-menu-item">-->
<!--              <a routerLink="link-opportunities" class="nk-menu-link" title="Link opportunities">-->
<!--              <span class="nk-menu-text">Link opportunities</span></a>-->
<!--            </li>-->
          </ul>
        </div>
      </div>
    </div>
    <div class="nk-sidebar-element nk-sidebar-bottom" *ngIf="!(isDemo$ | async)">
      <app-balance-view
        [balanceData]="balanceData$ | async"
        [tariffData]="tariffData$ | async"
      >
      </app-balance-view>
    </div>
  </div>
</section>
