import {Action, createReducer} from "@ngrx/store";

export interface ViewState {
  theme: string;
  device: string | undefined;
  connectionType: string | undefined;
}

const initialState: ViewState = {
  theme: 'light',
  connectionType: undefined,
  device: undefined
}

const _viewReducer = createReducer(
  initialState
)

export function viewReducer(state: ViewState | undefined, action: Action) {
  return _viewReducer(state, action)
}
