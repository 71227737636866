import {Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ElementRef} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {Router, ActivatedRoute } from "@angular/router";
import {AuthFacade} from "../../auth.facade";
import {jackInTheBoxOnEnterAnimation} from "angular-animations";
import {takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {environment} from "../../../../environments/environment";
import {GaService} from "../../../core/services/ga.service";
import {RecaptchaComponent, ReCaptchaV3Service} from "ng-recaptcha";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  encapsulation : ViewEncapsulation.None,
  animations: [
    jackInTheBoxOnEnterAnimation({duration: 500, delay: 100}),
  ]
})
export class SignInComponent implements OnInit, OnDestroy {
  
  isLoading: boolean = false;
  
  @ViewChild('captchaRef') captchaRef!: RecaptchaComponent;
  
  isShowPass: boolean = false;
  
  message!: string;
  typeOfMessage!: string;
  /**
   * Login for group
   * @type {FormGroup}
   */
  form: FormGroup;

  /**
   * Id of current user
   * @type {string}
   */
  userId!: string;

  /**
   * Token of current user
   * @type {string}
   */
  token!: string;

  /**
   * Getter for email control
   * @type {AbstractControl}
   */
  get email(): AbstractControl | null {
    return this.form.get('email');
  }

  /**
   * Getter for password control
   * @type {AbstractControl}
   */
  get password(): AbstractControl | null {
    return this.form.get('password');
  }

  /**
   * Subject for unsubscribing
   * @type {Subject<void>}
   */
  destroy$: Subject<void>;
  
  /**
   * Recaptcha site key
   * @type {string}
   */
  public SITE_ID_NG = environment.recaptchaKey;
  
  /**
   * Constructor for SignInComponent
   * @param {AuthFacade} authFacade
   * @param {Router} router
   * @param {ActivatedRoute} activatedRoute
   * @param {MatDialog} dialog
   * @param gaService
   * @param recaptchaV3Service
   */
  constructor(private router: Router,
              private authFacade: AuthFacade,
              private activatedRoute: ActivatedRoute,
              public dialog: MatDialog,
              private gaService: GaService,
              private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.form = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(1000)
      ])
    });

    this.destroy$ = new Subject<void>();
  }

  /**
   * Call on component init
   * @return {void}
   */
  ngOnInit(): void {
    this.activatedRoute.params
    .pipe(takeUntil(this.destroy$))
    .subscribe(params => {
      if (params.token && params.userId) {
        this.userId = params.userId;
        this.token = params.token;

        this.verifyUser(this.userId, this.token);
      } else if (params.uid && params.onetime_pass) {
        this.loginByOnetimePass(params.uid, params.onetime_pass);
      }
    })
  
    if (this.router.url.indexOf('/socialite_provider') === 0) {
      let code = '';
      let scope = '';
      let authuser = '';
      let session_state = '';
      let prompt = '';
      const provider = this.router.url.includes('/google/') ? 'google' : (this.router.url.includes('/facebook/') ? 'facebook' : 'github');
      this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        code = params['code'];
        scope = params['scope'];
        authuser = params['authuser'];
        session_state = params['session_state'];
        prompt = params['prompt'];
        const affId = (localStorage.getItem('referralID')) ? localStorage.getItem('referralID') : null;
        const ga_cid = this.gaService.getGaCid();

        this.authFacade.loginByThirdParty(provider, code, scope, authuser, session_state, prompt, affId, ga_cid)
      });
    }
    this.authFacade.getLoadingState().subscribe((load) => {
      this.isLoading = load
    })
  }

  /**
   * Change page handler
   * @return {void}
   */
  goToForgot(): void {
    this.router.navigate(['/forgot-pass']);
  }

  /**
   * Change page handler
   * @return {void}
   */
  goToSignUp(): void {
    this.router.navigate(['/sign-up']);
  }
  
  goToThirdParty(part: string) {
    this.authFacade.goToThirdParty(part).subscribe((url) => {
      window.location.href = url;
    })
  }

  /**
   * Call action to login user
   * @return {void}
   */
  loginUser(captcha:string): void {
    if (this.form.valid) {
      this.authFacade.loginUser({...this.form.value, recaptcha: captcha})
    }
    
  }
  
  loginTmp() {
    if (this.form.valid) {
      this.authFacade.loginTmp()
      this.router.navigate(['/overview']);
    }
    
  }
  
  recaptchaSolve() {
    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      this.loginUser(token)
    });
  }

  /**
   * Request for verify user
   * @param {string} userId 
   * @param {string} token
   * @return {void} 
   */
  verifyUser(userId: string, token: string): void {
    this.authFacade.verifyUser(userId, token)
  
  }
  
  loginByOnetimePass(uid: string, onetime_pass: string) {
    this.authFacade.loginByOnetimePass(uid, onetime_pass);
  }

  /**
   * Call on component destroy
   * @return {void}
   */
  ngOnDestroy(): void {
    this.destroy$.next();
  }
  
  showPass(e: any) {
    this.isShowPass = !this.isShowPass;
  }

}
