import {AppState} from "../app.reducer";
import {createSelector} from "@ngrx/store";
import {BalanceState} from "./balance.reducer";

export const selectBalance = (state: AppState) => state.balance;

export const balanceState = createSelector(
  selectBalance,
  (state: BalanceState) => state
)

export const balanceErrors = createSelector(
	selectBalance,
	(state: BalanceState) => state.errors
)
