import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { jackInTheBoxOnEnterAnimation } from "angular-animations";
import { AuthFacade } from '../../auth.facade';
import { MustMatch } from '../sign-up/sign-up.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import {environment} from "../../../../environments/environment";
import {getPasswordStrength} from "../../../core/utils/utils";

@Component({
  selector: 'linkinsider-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.scss'],
  animations: [
    jackInTheBoxOnEnterAnimation({duration: 500, delay: 100}),
  ]
})
export class ResetPassComponent implements OnInit, OnDestroy {
  
  /**
   * Recaptcha site key
   * @type {string}
   */
  public SITE_ID_NG = environment.recaptchaKey;
  
  passwordStrengthBar: {value: number} = {
    value: 0
  }
  
  isShowPass: boolean = false;
  
  
  loading: boolean = false;
  /**
   * Reset password data form
   * @type {FormGroup}
   */
  form: FormGroup;

  /**
   * Getter for password control
   * @type {AbstractControl}
   */
  get password(): AbstractControl | null {
    return this.form.get('password');
  }

  /**
   * Getter for form confirm password control
   * @type {AbstractControl}
   */
  get passwordConfirm(): AbstractControl | null {
    return this.form.get('passwordConfirm');
  }
  
  /**
   * Subject for unsubscribing
   * @type {Subject<void>}
   */
  destroy$: Subject<void>;

  /**
   * Constructor for ForgotPassComponent
   * @type {AuthFacade} authFacade
  */
  constructor(private authFacade: AuthFacade,
              private activatedRoute: ActivatedRoute) {
    this.form = new FormGroup({
      user_id: new FormControl(),
      token: new FormControl(),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8)
      ]),
      passwordConfirm: new FormControl('', [

      ])
    }, {validators: MustMatch('password', 'passwordConfirm')});

    this.destroy$ = new Subject<void>();
  }

  /**
   * Call on component init
   * @return {void}
   */
  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.form.get('user_id')?.setValue(params.user_id);
        this.form.get('token')?.setValue(params.token);
      })
    this.authFacade.getLoadingState().subscribe(loadState => this.loading = loadState)
  }

  /**
   * Reset current password & send new to database
   * @type {string} captcha
   * @return {void}
   */
  sendNewPass(captcha:string): void {
    if (this.form.valid) {
      this.form.get('password')?.setValue(this.form.value.password);
      this.form.get('passwordConfirm')?.setValue(this.form.value.passwordConfirm);
      this.authFacade.sendNewPass(this.form.value, captcha);
    }
  }

  /**
   * Call on component destroy
   * @return {void}
   */
  ngOnDestroy(): void {
    this.destroy$.next();
  }
  
  
  showPass(e: any) {
    this.isShowPass = !this.isShowPass;
  }
  
  checkPasswordStrength() {
    this.passwordStrengthBar.value = getPasswordStrength(this.password?.value)
  }
}
