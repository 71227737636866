import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { fadeInDownOnEnterAnimation} from "angular-animations";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {getUserData, isDemo, isUserAuthenticated} from "../../store/user/user.selector";
import {AppState} from "../../store/app.reducer";
import {authenticate, signOut} from "../../store/user/user.actions";
import {setDomainData} from "../../store/domain/domain.actions";
import {User} from "../../models/user.model";
import {DomainData} from "../../models/domain-data.model";
import {selectDomainData} from "../../store/domain/domain.selectors";
import {BalanceState} from "../../store/balance/balance.reducer";
import {balanceState} from "../../store/balance/balance.selectors";
import {initBalanceData} from "../../store/balance/balance.actions";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    fadeInDownOnEnterAnimation({anchor: 'enter', duration: 500})
  ]
})
export class HeaderComponent implements OnInit {

  isUserAuthenticated$: Observable<boolean>;
  isUserDemo$: Observable<boolean>;
  
  userData$: Observable<User | undefined>;
  
  /**
   * Contains domain url and domain type id from store
   * @type {DomainData}
   */
  public domainData$: Observable<DomainData | null>;
  
  public balanceData$: Observable<BalanceState> | null;
  
  showIntercom: boolean = true;
  
  /**
   * Emmit event for left menu toggle
   * @type {EventEmitter<void>}
   */
  @Output() menuToggle: EventEmitter<void> = new EventEmitter<void>()

  constructor(private store: Store<AppState>) {
    this.isUserAuthenticated$ = store.select(isUserAuthenticated)
    this.userData$ = store.select(getUserData);
    this.isUserDemo$ = store.select(isDemo)
    this.userData$.subscribe((user => {
      if(user) {
        this.showIntercom = user.show_intercom;
        if (this.showIntercom) {
          (window as any).Intercom('boot', {
            app_id: 'yhwl2kwv',
            user_hash: user.intercom_hash,
            vertical_padding: 100,
            created_at: user.created_at,
            name: user.name,
            user_name: user.name,
            email: user.email,
            language: user.language,
            // domain: environment.appUrl,
            domain: 'app.seoinsider.com',
            hide_default_launcher: false,
            link_auth_li: 'https://app.seoinsider.com/login_onetime_admin/' + user.id + '/' + user.intercom_token,
          });
        } else if (!this.showIntercom) {
          (window as any).Intercom('shutdown');
        }
      }
    }));
    this.domainData$ = store.select(selectDomainData);
    this.balanceData$ = store.select(balanceState);
  
    
    
  }

  ngOnInit(): void {
    this.initBalanceData(null);
  }

  login() {
    // this.store.dispatch(authenticate())
  }

  logout() {
    this.store.dispatch(signOut())
  }

  saveDomainDataToState(domainData: DomainData) {
    this.store.dispatch(setDomainData(domainData));
  }
  
  initBalanceData(domain: string | null) {
    this.store.dispatch(initBalanceData({domain}))
  }
  
  /**
   * Left menu toggle handler
   * @return {void}
   */
  menuToggleHandler(): void {
    this.menuToggle.emit()
  }
}
