import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

interface IConfirmPopup {
  message: string;
  title: string;
  type?: string;
}
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  
  constructor(
      public dialog: MatDialogRef<ConfirmDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: IConfirmPopup = {
        message: '',
        title: 'Confirm'
      }) { }

  ngOnInit(): void {
  }
  
  closeDialog() {
    this.dialog.close(false)
  }
  
  confirm() {
    this.dialog.close(true);
  }

}
