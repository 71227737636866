import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'linkinsider-error-verify-component',
  templateUrl: './error-verify-component.component.html',
  styleUrls: ['./error-verify-component.component.scss']
})
export class ErrorVerifyComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {message: string}) { }

  ngOnInit(): void {
  }

}
