import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header.component';
import {DomainControlComponent} from './components/domain-control/domain-control.component';
import {ReactiveFormsModule} from "@angular/forms";
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatRippleModule} from '@angular/material/core';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import {RouterModule} from "@angular/router";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";


@NgModule({
  declarations: [
    HeaderComponent,
    DomainControlComponent,
    UserMenuComponent
  ],
	imports: [CommonModule, RouterModule, ReactiveFormsModule, MatSelectModule, MatRippleModule, MatInputModule, MatButtonModule, MatIconModule],
  exports: [HeaderComponent, DomainControlComponent]
})
export class HeaderModule {
}
