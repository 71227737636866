<section class="app nk-body">
	<div class="left-menu">
		<app-left-menu
			[isShown]="isLeftMenuShown"
			(menuToggle)="menuToggle()"></app-left-menu>
	</div>
	<div class="content">
		<div class="left-bg" [class.hidden]="!isLeftMenuShown" (click)="menuToggle()"></div>
		<app-header (menuToggle)="menuToggle()"></app-header>

		<div class="mt-5">
			<router-outlet></router-outlet>
		</div>
	</div>
</section>
