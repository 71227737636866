import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppState} from "../../core/store/app.reducer";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {selectDomainData} from "../../core/store/domain/domain.selectors";

@Injectable()
export class CurrentDomainGuard implements CanActivate {
	
	constructor(private store: Store<AppState>, private router: Router) {
	}
	
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		return this.store.select(selectDomainData)
		.pipe(
			map(data => {
				if (data.domain) {
					return true;
				}
				this.router.navigate(['domains'])
				return false;
			})
		);
	}
}
