export type CollectionPredicate = (item?: any, index?: number, collection?: any[]) => boolean;

export function isUndefined(value: any): value is undefined {
	return typeof value === 'undefined';
}

export function isNull(value: any): value is null {
	return value === null;
}

export function isNumber(value: any): value is number {
	return typeof value === 'number';
}

export function isNumberFinite(value: any): value is number {
	return isNumber(value) && isFinite(value);
}

// Not strict positive
export function isPositive(value: number): boolean {
	return value >= 0;
}

export function isInteger(value: number): boolean {
	// No rest, is an integer
	return value % 1 === 0;
}

export function isNil(value: any): value is null | undefined {
	return value === null || typeof value === 'undefined';
}

export function isString(value: any): value is string {
	return typeof value === 'string';
}

export function isObject(value: any): boolean {
	return value !== null && typeof value === 'object';
}

export function isArray(value: any): boolean {
	return Array.isArray(value);
}

export function isFunction(value: any): boolean {
	return typeof value === 'function';
}

export function toDecimal(value: number, decimal: number): number {
	return Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal);
}

export function upperFirst(value: string): string {
	return value.slice(0, 1).toUpperCase() + value.slice(1);
}

export function createRound(method: string): Function {
	// <any>Math to suppress error
	const func: any = (<any>Math)[method];
	return function(value: number, precision: number = 0) {
		if (typeof value === 'string') {
			throw new TypeError('Rounding method needs a number');
		}
		
		if (typeof precision !== 'number' || isNaN(precision)) {
			precision = 0;
		}
		
		if (precision) {
			let pair = `${value}e`.split('e');
			const val = func(`${pair[0]}e` + (+pair[1] + precision));
			
			pair = `${val}e`.split('e');
			return +(pair[0] + 'e' + (+pair[1] - precision));
		}
		
		return func(value);
	};
}

export function leftPad(str: string, len: number = 0, ch: any = ' ') {
	str = String(str);
	ch = toString(ch);
	let i = -1;
	const length = len - str.length;
	
	while (++i < length && str.length + ch.length <= len) {
		str = ch + str;
	}
	
	return str;
}

export function rightPad(str: string, len: number = 0, ch: any = ' ') {
	str = String(str);
	ch = toString(ch);
	
	let i = -1;
	const length = len - str.length;
	
	while (++i < length && str.length + ch.length <= len) {
		str += ch;
	}
	
	return str;
}

export function toString(value: number | string) {
	return `${value}`;
}

export function pad(str: string, len: number = 0, ch: any = ' '): string {
	str = String(str);
	ch = toString(ch);
	let i = -1;
	const length = len - str.length;
	
	let left = true;
	while (++i < length) {
		const l = str.length + ch.length <= len ? str.length + ch.length : str.length + 1;
		
		if (left) {
			str = leftPad(str, l, ch);
		} else {
			str = rightPad(str, l, ch);
		}
		
		left = !left;
	}
	
	return str;
}

export function flatten(input: any[], index: number = 0): any[] {
	if (index >= input.length) {
		return input;
	}
	
	if (isArray(input[index])) {
		return flatten(input.slice(0, index).concat(input[index], input.slice(index + 1)), index);
	}
	
	return flatten(input, index + 1);
}

export function getProperty(value: { [key: string]: any }, key: string): any {
	if (isNil(value) || !isObject(value)) {
		return undefined;
	}
	
	const keys: string[] = key.split('.');
	let result: any = value[keys.shift()!];
	
	for (const key of keys) {
		if (isNil(result) || !isObject(result)) {
			return undefined;
		}
		
		result = result[key];
	}
	
	return result;
}

export function sum(input: Array<number>, initial = 0): number {
	return input.reduce((previous: number, current: number) => previous + current, initial);
}

// http://stackoverflow.com/questions/6274339/how-can-i-shuffle-an-array-in-javascript
export function shuffle(input: any): any {
	if (!isArray(input)) {
		return input;
	}
	
	const copy = [...input];
	
	for (let i = copy.length; i; --i) {
		const j = Math.floor(Math.random() * i);
		const x = copy[i - 1];
		copy[i - 1] = copy[j];
		copy[j] = x;
	}
	
	return copy;
}

export function deepIndexOf(collection: any[], value: any) {
	let index = -1;
	const length = collection.length;
	
	while (++index < length) {
		if (deepEqual(value, collection[index])) {
			return index;
		}
	}
	
	return -1;
}

export function deepEqual(a: any, b: any) {
	if (a === b) {
		return true;
	}
	
	if (!(typeof a === 'object' && typeof b === 'object')) {
		return a === b;
	}
	
	const keysA = Object.keys(a);
	const keysB = Object.keys(b);
	
	if (keysA.length !== keysB.length) {
		return false;
	}
	
	// Test for A's keys different from B.
	var hasOwn = Object.prototype.hasOwnProperty;
	for (let i = 0; i < keysA.length; i++) {
		const key = keysA[i];
		if (!hasOwn.call(b, keysA[i]) || !deepEqual(a[key], b[key])) {
			return false;
		}
	}
	
	return true;
}

export function isDeepObject(object: any) {
	return object.__isDeepObject__;
}

export function wrapDeep(object: any) {
	return new DeepWrapper(object);
}

export function unwrapDeep(object: any) {
	if (isDeepObject(object)) {
		return object.data;
	}
	
	return object;
}

export class DeepWrapper {
	public __isDeepObject__: boolean = true;
	
	constructor(public data: any) {}
}

export function count(input: any): any {
	if (!isArray(input) && !isObject(input) && !isString(input)) {
		return input;
	}
	
	if (isObject(input)) {
		return Object.keys(input).map(value => input[value]).length;
	}
	
	return input.length;
}

export function empty(input: any): any {
	if (!isArray(input)) {
		return input;
	}
	
	return input.length === 0;
}

export function every(input: any, predicate: CollectionPredicate) {
	if (!isArray(input) || !predicate) {
		return input;
	}
	
	let result = true;
	let i = -1;
	
	while (++i < input.length && result) {
		result = predicate(input[i], i, input);
	}
	
	return result;
}

export function takeUntil(input: any[], predicate: CollectionPredicate) {
	let i = -1;
	const result: any = [];
	while (++i < input.length && !predicate(input[i], i, input)) {
		result[i] = input[i];
	}
	
	return result;
}

export function transformHttpError(some: any) {
	let error = '';
	for (let key in some) {
		let str = some[key].join(' ');
		error += str;
	}
	
	return error;
}

export function checkIfNullableArray(array: number[]): boolean {
	return !array.every(elem => elem === 0)
}

export function getPasswordStrength(password: string): number {
	let strength = 0;
	if (password.match(/[a-zа-я]+/)) {
		strength += 1;
	}
	if (password.match(/[A-ZА-Я]+/)) {
		strength += 1;
	}
	if (password.match(/[0-9]+/)) {
		strength += 1;
	}
	if (password.match(/[$@#&!]+/)) {
		strength += 1;
	}
	
	return strength;
}

export function toISODate(date: Date): string {
	return date.toISOString().slice(0, 10);
}

export function getCSSVariableValue(variableName: string) {
	let hex = getComputedStyle(document.documentElement).getPropertyValue(variableName)
	if (hex && hex.length > 0) {
		hex = hex.trim()
	}
	
	return hex
}

export function deepCompare (...args : any) {
	var i, l, leftChain: any, rightChain: any;
	
	function compare2Objects (x: any, y: any) {
		var p;
		
		// remember that NaN === NaN returns false
		// and isNaN(undefined) returns true
		if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
			return true;
		}
		
		// Compare primitives and functions.
		// Check if both arguments link to the same object.
		// Especially useful on the step where we compare prototypes
		if (x === y) {
			return true;
		}
		
		// Works in case when functions are created in constructor.
		// Comparing dates is a common scenario. Another built-ins?
		// We can even handle functions passed across iframes
		if ((typeof x === 'function' && typeof y === 'function') ||
			(x instanceof Date && y instanceof Date) ||
			(x instanceof RegExp && y instanceof RegExp) ||
			(x instanceof String && y instanceof String) ||
			(x instanceof Number && y instanceof Number)) {
			return x.toString() === y.toString();
		}
		
		// At last checking prototypes as good as we can
		if (!(x instanceof Object && y instanceof Object)) {
			return false;
		}
		
		if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
			return false;
		}
		
		if (x.constructor !== y.constructor) {
			return false;
		}
		
		if (x.prototype !== y.prototype) {
			return false;
		}
		
		// Check for infinitive linking loops
		if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
			return false;
		}
		
		// Quick checking of one object being a subset of another.
		// todo: cache the structure of arguments[0] for performance
		for (p in y) {
			if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
				return false;
			}
			else if (typeof y[p] !== typeof x[p]) {
				return false;
			}
		}
		
		for (p in x) {
			if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
				return false;
			}
			else if (typeof y[p] !== typeof x[p]) {
				return false;
			}
			
			switch (typeof (x[p])) {
				case 'object':
				case 'function':
					
					leftChain.push(x);
					rightChain.push(y);
					
					if (!compare2Objects (x[p], y[p])) {
						return false;
					}
					
					leftChain.pop();
					rightChain.pop();
					break;
				
				default:
					if (x[p] !== y[p]) {
						return false;
					}
					break;
			}
		}
		
		return true;
	}
	
	if (arguments.length < 1) {
		return true; //Die silently? Don't know how to handle such case, please help...
		// throw "Need two or more arguments to compare";
	}
	
	for (i = 1, l = arguments.length; i < l; i++) {
		
		leftChain = []; //Todo: this can be cached
		rightChain = [];
		
		if (!compare2Objects(arguments[0], arguments[i])) {
			return false;
		}
	}
	
	return true;
}

export const minusDays = function (dt: Date, days: number) {
	const date = new Date(dt.valueOf())
	date.setDate(date.getDate() - days)
	return date
}

export function shallowEqual(object1:any, object2:any) {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);
	if (keys1.length !== keys2.length) {
		return false;
	}
	for (let key of keys1) {
		if (object1[key] !== object2[key]) {
			return false;
		}
	}
	return true;
}

export function secondsToTimeString(sec: number) {
	// return 14 hours 35 minutes 25 seconds || 35 minutes 25 seconds || 25 seconds
	const hours   = Math.floor(sec / 3600)
	const minutes = Math.floor(sec / 60) % 60
	const seconds = sec % 60
	
	const hDisplay = hours > 0 ? hours + (hours == 1 ? " hour " : " hours ") : "";
	const mDisplay = minutes > 0 ? minutes + (minutes == 1 ? " minute " : " minutes ") : "";
	const sDisplay = seconds > 0 ? seconds + (seconds == 1 ? " second" : " seconds") : "";
	
	return hDisplay + mDisplay + sDisplay;
}


export function getDomainFromTarget(target: string): string {
	let domain = target.trim().toLowerCase();
	domain = domain.replace('https://', '');
	domain = domain.replace('http://', '');
	domain = domain.replace('www.', '');
	const slash = domain.indexOf('/');
	if (slash >= 0) {
		domain = domain.slice(0, slash);
	}
	
	return domain;
}
