import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { jackInTheBoxOnEnterAnimation } from "angular-animations";
import { AuthFacade } from '../../auth.facade';
import {Store} from "@ngrx/store";
import {AppState} from "../../../core/store/app.reducer";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {ReCaptchaV3Service} from "ng-recaptcha";

@Component({
  selector: 'linkinsider-forgot-pass',
  templateUrl: './forgot-pass.component.html',
  styleUrls: ['./forgot-pass.component.scss'],
  animations: [
    jackInTheBoxOnEnterAnimation({duration: 500, delay: 100}),
  ]
})
export class ForgotPassComponent implements OnInit {
  
  loading: boolean = false;
  /**
   * Recaptcha site key
   * @type {string}
   */
  public SITE_ID_NG = environment.recaptchaKey;
  
  /**
   * Forgot password data form
   * @type {FormGroup}
   */
  form: FormGroup;

  /**
   * Getter for form email control
   * @type {AbstractControl}
   */
  get email(): AbstractControl | null {
    return this.form.get('email');
  }

  /**
   * Constructor for ForgotPassComponent
   * @type {AuthFacade} authFacade
   */
  constructor(private authFacade: AuthFacade, private store: Store<AppState>, private recaptchaV3Service: ReCaptchaV3Service) {
    this.form = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
      ])
    });
  }

  /**
   * Call on component init
   * @return {void}
   */
  ngOnInit(): void {
    
    this.authFacade.getLoadingState().subscribe(loadState => this.loading = loadState);
  }

  /**
   * Call action to send reset password instructions to email
   * @return {void}
   */
  sendEmailForReset(captcha:string): void {
    if (this.form.valid) 
      this.authFacade.sendEmailForReset(this.form.value.email, captcha);
  }
  
  recaptchaSolve() {
    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      this.sendEmailForReset(token)
    });
  }
}
