import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {AuthFacade} from "../../auth.facade";
import {jackInTheBoxOnEnterAnimation} from "angular-animations";
import {BehaviorSubject, Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {GaService} from "../../../core/services/ga.service";
import {Router} from "@angular/router";
import {getPasswordStrength} from "../../../core/utils/utils";
import {ReCaptchaV3Service} from "ng-recaptcha";

export function MustMatch(controlName: string, checkControlName: string): ValidatorFn {
  return (controls: AbstractControl) => {
    const control = controls.get(controlName);
    const checkControl = controls.get(checkControlName);

    if (checkControl?.errors && !checkControl.errors.matching) {
      return null;
    }

    if (control?.value !== checkControl?.value) {
      controls.get(checkControlName)?.setErrors({ matching: true });
      return { matching: true };
    } else {
      return null;
    }
  }
}

@Component({
  selector: 'linkinsider-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss', '../sign-in/sign-in.component.scss'],
  encapsulation : ViewEncapsulation.None,
  animations: [
    jackInTheBoxOnEnterAnimation({duration: 500, delay: 100}),
  ]
})
export class SignUpComponent implements OnInit {
  
  registrationEnabled: boolean = false;
  
  isLoading: boolean = false;
  
  referralID: string|null = null
  
  isShowPass: boolean = false;
  
  passwordStrengthBar: {value: number} = {
    value: 0
  }
  
  showErrors$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  /**
   * Recaptcha site key
   * @type {string}
   */
  public SITE_ID_NG = environment.recaptchaKey;
  /**
   * Register data form
   * @type {FormGroup}
   */
  form: FormGroup;

  /**
   * Getter for form name control
   * @type {AbstractControl}
   */
  get name(): AbstractControl | null {
    return this.form.get('name');
  }

  /**
   * Getter for form email control
   * @type {AbstractControl}
   */
  get email(): AbstractControl | null {
    return this.form.get('email');
  }

  /**
   * Getter for form password control
   * @type {AbstractControl}
   */
  get password(): AbstractControl | null {
    return this.form.get('password');
  }
  
  get accept(): AbstractControl | null {
    return this.form.get('acceptTerms');
  }

  /**
   * Getter for form confirm password control
   * @type {AbstractControl}
   */
  get passwordConfirm(): AbstractControl | null {
    return this.form.get('passwordConfirm');
  }

  /**
   * Constructor for SignUpComponent
   * @type {AuthFacade} authFacade
   */
  constructor(
      private authFacade: AuthFacade,
      private gaService: GaService,
      private router: Router,
      private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.form = new FormGroup({
      name: new FormControl('', [
        Validators.minLength(2)
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(1000)
      ]),
      passwordConfirm: new FormControl('', [
      
      ]),
      acceptTerms: new FormControl(false, Validators.requiredTrue)
    }, {validators: MustMatch('password', 'passwordConfirm')})
    
    this.form.valueChanges.subscribe(data => {
      // this.showErrors$.next(false);
    })
  }

  /**
   * Call on component init
   * @return {void}
   */
  ngOnInit(): void {
    this.referralID = (localStorage.getItem('referralID')) ? localStorage.getItem('referralID') : null;
    this.authFacade.getLoadingState().subscribe(load => {
      this.isLoading = load
    });
  }
  
  recaptchaSolve() {
    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      this.registerUser(token)
    });
  }
  
  
  /**
   * Call action to register user
   * @return {void}
   */
  registerUser(captcha:string): void {
    if (this.form.valid){
      // const ga_cid = this.gaService.getGaCid();
      this.authFacade.registerUser({...this.form.value, recaptcha: captcha});
    } else {
      this.showErrors$.next(true);
    }
  }
  
  registerTmp(): void {
    if (this.form.valid) {
      this.authFacade.loginTmp();
      this.router.navigate(['/overview']);
    } else {
      this.showErrors$.next(true);
    }
  }
  
  goToThirdParty(part: string) {
    this.authFacade.goToThirdParty(part).subscribe((url) => {
      window.location.href = url;
    })
  }
  
  showPass(e: any) {
    this.isShowPass = !this.isShowPass;
  }
  
  checkPasswordStrength() {
    this.passwordStrengthBar.value = getPasswordStrength(this.password?.value)
  }
}
