import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {setBalanceData, initBalanceData} from "./balance.actions";
import {exhaustMap, map} from "rxjs/operators";
import {OverviewApi} from "../../../products/overview/api/overview.api";

@Injectable()
export class BalanceEffect {
	
	getBalance$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(initBalanceData),
			exhaustMap(payload => this.overviewApi.getBalance(payload.domain)
					.pipe(
						map(balanceData => {
							return setBalanceData({...balanceData});
						})
					)
			)
		)
	})
	
	constructor(
		private actions$: Actions, private overviewApi: OverviewApi
	) {}
}
