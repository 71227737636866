import {Injectable} from "@angular/core";
import {AccountApi} from "./api/account.api";
import {AccountState} from "./state/account.state";
import {Observable} from "rxjs";
import {User, UserSocialAccount} from "../core/models/user.model";
import {UpdateUserDataModel} from "./models/update-user-data.model";
import {map, take} from "rxjs/operators";
import {IPayment} from "./models/payment.model";
import {CommonService} from "../core/services/common.service";

@Injectable()
export class AccountFacade {
	constructor(private api: AccountApi, private state: AccountState, private cms: CommonService) {
	}
	
	updateUserData(formData: UpdateUserDataModel) {
		this.state.updateUserData(formData)
	}
	
	getUserData(): Observable<User | undefined> {
		return this.state.getUserData()
	}
	
	getLoading(): Observable<boolean> {
		return this.state.getStateLoading();
	}
	
	revokeAccount(provider: string = 'google') {
		return this.api.revokeAccount(provider);
	}
	
	goToThirdParty(part: string): Observable<string> {
		return this.api.goToThirdParty(part).pipe(
			take(1),
			map(res => res.target_url)
		)
	}
	
	getGoogleDomains(type: string): Observable<string[]> {
		if (type === 'hard') {
			return this.api.getGoogleDomainsDirectly().pipe(
				map(res => res.map(domain => domain.replace('sc-domain:', '')))
			);
		}
		return this.api.getGoogleDomains().pipe(
			map(res => res.map(domain => domain.replace('sc-domain:', '')))
		);
	}
	
	getSocialAccount(): Observable<UserSocialAccount> {
		return this.api.getSocialAccount().pipe(
			map(res => res.data)
		)
	}
	
	getPayments(): Observable<IPayment[]> {
		return this.api.getPayments().pipe(
			map(res => res.data)
		)
	}
	
	getCurrentDateFormat(): string {
		return this.cms.getCurrentUserDateFormat();
	}
	
	deleteUser(): Observable<{ success: boolean }> {
		return this.api.deleteUser().pipe(take(1), map(response => response.data));
	}
	
	logOut() {
		return this.state.logOut();
	}
}
