import {Store} from "@ngrx/store";
import {AppState} from "../store/app.reducer";
import {Injectable} from "@angular/core";
import {getUserData} from "../store/user/user.selector";

@Injectable()
export class CommonService {


	public constructor(private store: Store<AppState>) {
	}
	
	/* Convert date format to use in formatDate function
	* @param {string} val
	* @summary Runtime Complexity = O(1)
		* @returns {string}
	*/
	convertDateTime(val: string | undefined): string {
		if (val) {
			return val.replace('d', 'dd').replace('m', 'MM').replace('y', 'yy').replace('Y', 'yyyy').replace('H', 'HH').replace('h', 'hh').replace('i', 'mm').replace('s', 'ss').replace('A', 'aa');
		}
		return '';
	}
	
	getCurrentUserDateFormat() {
		let userDateFormat: string | undefined = 'dd/MM/yyyy';
		const user$ = this.store.select(getUserData);
		user$.subscribe(
			userData => {
				if (userData) {
					userDateFormat = this.convertDateTime(userData.date_format.split(' ').join(userData.date_format_separator));
				}
			}
		)
		return userDateFormat;
	}
}
