import {AppState} from "../app.reducer";
import {createSelector} from "@ngrx/store";
import {DomainState} from "./domain.reducer";

export  const selectDomain = (state: AppState) => state.domain;

export const selectDomainData = createSelector(
  selectDomain,
  (state: DomainState) => state
)
