import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {AppState} from "../../core/store/app.reducer";
import {signOut, updateUserInfo} from "../../core/store/user/user.actions";
import {getUserData, isLoading} from "../../core/store/user/user.selector";
import {Observable} from "rxjs";
import {User} from "../../core/models/user.model";
import {UpdateUserDataModel} from "../models/update-user-data.model";

@Injectable()
export class AccountState {
	
	constructor(private store: Store<AppState>) {
	}
	
	getUserData(): Observable<User | undefined> {
		return this.store.select(getUserData)
	}
	
	updateUserData(formData: UpdateUserDataModel) {
		this.store.dispatch(updateUserInfo(formData));
	}
	
	getStateLoading() {
		return this.store.select(isLoading);
	}
	
	logOut() {
		this.store.dispatch(signOut())
	}
}
