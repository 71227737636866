import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {AppState} from "../../core/store/app.reducer";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment
} from "@angular/router";
import {Observable} from "rxjs";
import {isUserAuthenticated} from "../../core/store/user/user.selector";
import {tap} from "rxjs/operators";

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private store: Store<AppState>,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.store.select(isUserAuthenticated)
      .pipe(tap(isAuth => {
        if (!isAuth) {
          const initialRequestedUrl = state.url;
          if(initialRequestedUrl && initialRequestedUrl.trim() !== '' && initialRequestedUrl.trim() !== '/404'){
            localStorage.setItem('last_requested_url', JSON.stringify({url: (initialRequestedUrl.split('?')[0]), time: Date.now()}) );
          }
          this.router.navigate(['auth'])
        }
        
      }));
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | boolean {
    return this.store.select(isUserAuthenticated)
      .pipe(tap(isAuth => {
        if (!isAuth)
          this.router.navigate(['auth'])
      }));
  }
}
