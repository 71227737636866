import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftMenuComponent } from './left-menu.component';
import {RouterModule} from "@angular/router";
import {BalanceViewComponent} from "./components/balance-view/balance-view.component";



@NgModule({
  declarations: [
    LeftMenuComponent,
    BalanceViewComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [LeftMenuComponent]
})
export class LeftMenuModule { }
