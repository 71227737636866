import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {
  OverviewAnchors, OverviewReferringDomains, OverviewTimeseriesInfo, OverviewTimeseriesNewLostInfo, ProfileOverview
} from "../models/profile-overview.model";

@Injectable()
export class OverviewApi {

  constructor(private httpClient: HttpClient) { }

  /**
   * Get main data for overview page
   * @param {string} target
   * @param {number} type
   */
  getProfileOverViewData(target: string, type: number) {
    const postData = {
      target,
      type
    }

    return this.httpClient.post<{data: ProfileOverview}>('backlinks_api/api/insider/profile_overview', postData);
  }
  
  /**
   * @param target
   * @param date_from
   * @param date_to
   * @param group_range
   */
  getTimeseriesInfo(target: string, date_from: string, date_to: string, group_range: string) {
    const postData = {
      target,
      date_from,
      date_to,
      group_range
    }
    
    return this.httpClient.post<{data: OverviewTimeseriesInfo}>('backlinks_api/api/insider/timeseries_info', postData);
  }
  
  /**
   * @param target
   * @param date_from
   * @param date_to
   * @param group_range
   */
  getTimeseriesNewLostInfo(target: string, date_from: string, date_to: string, group_range: string) {
    const postData = {
      target,
      date_from,
      date_to,
      group_range
    }
    
    return this.httpClient.post<{data: OverviewTimeseriesNewLostInfo}>('backlinks_api/api/insider/timeseries_new_lost_info', postData);
  }
  
  getTopAnchors(target: string, type: number) {
    const postData = {
      target,
      type
    }
  
    return this.httpClient.post<{data: OverviewAnchors}>('backlinks_api/api/insider/top_anchors_chart', postData);
  }
  
  getTopReferringDomains(target: string, type: number) {
    const postData = {
      target,
      type
    }
    
    return this.httpClient.post<{data: OverviewReferringDomains}>('backlinks_api/api/insider/top_referring_domains', postData);
  }
  
  getBalance(target: string | null) {
    const postData = {
      target: target
    }
    return this.httpClient.post<any>('backlinks_api/api/balance/get', postData);
  }
}
