import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {
  fadeInOnEnterAnimation,
  jackInTheBoxOnEnterAnimation,
  zoomInDownOnEnterAnimation,
  zoomOutOnLeaveAnimation
} from "angular-animations";
import {AuthFacade} from "../../auth.facade";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'linkinsider-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  animations: [
    zoomInDownOnEnterAnimation({duration: 500, delay: 100}),
    zoomOutOnLeaveAnimation({duration: 300}),
    jackInTheBoxOnEnterAnimation({duration: 500, delay: 100}),
    fadeInOnEnterAnimation()
  ]
})
export class AuthComponent implements OnInit, OnDestroy, AfterViewInit {

  /**
   * Subject to destroy all subscriptions
   * @type {Subject<void>}
   */
  destroy$: Subject<void>;
  
  
  public SITE_ID_NG = environment.recaptchaKey;
  
  /**
   * Constructor for AuthComponent
   * @type {AuthFacade} authFacade
   * @type {ActivatedRoute} activatedRoute
   */
  constructor(private authFacade: AuthFacade,
              private activatedRoute: ActivatedRoute) {
    this.destroy$ = new Subject<void>();
  }

  /**
   * Call on component init
   * @return {void}
   */
  ngOnInit(): void {
    this.activatedRoute.queryParamMap
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(params => {
        this.saveReferralIdFromRoute(params);
      })
  }

  /**
   * Check route params and save referralID && friendID to localStorage if they are exist
   * @param {ParamMap} params
   * @private
   */
  private saveReferralIdFromRoute(params: ParamMap) {
    if (params.has('aff')) {
      const referralID = (params.get('aff')) || '';
      localStorage.setItem('referralID', parseInt(referralID) > 0 ? parseInt(referralID).toString() : '')
    }

    if (params.has('friendID')) {
      const friendID = params.get('friendID') || '';
      localStorage.setItem('friendID', friendID)
    }
  }
  
  ngAfterViewInit() {
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'visible';
    }
  }
  
  /**
   * Call on component destroy
   * @return {void}
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'hidden';
    }
  }

}
