import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {setDomain, setDomainData} from "./domain.actions";
import {map} from "rxjs/operators";
import {DomainData} from "../../models/domain-data.model";
import {User} from "../../models/user.model";

@Injectable()
export class DomainEffect {
	
	private setDomainDataToLocalStorage(domainData: DomainData): void {
		localStorage.setItem('last_requested_domain_data', JSON.stringify(domainData));
	}
	
	setDomains$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(setDomainData),
			map(domain => {
				const newDomainValue = this.resolveTarget(domain.domain, domain.domainTypeId)
				// console.log(newDomainValue);
				this.setDomainDataToLocalStorage(newDomainValue);
				return newDomainValue
			})
		)
	}, {dispatch: false})
	
	constructor(
		private actions$: Actions,
	) {}
	
	private resolveTarget(target: string, type: number): DomainData {
		// let url = target;
		// let newType = type;
		// if (!/^https?:\/\//i.test(url)) {
		// 	url = 'https://' + url;
		// }
		// const urlObj = new URL(url)
		// let newTarget = target;
		// if (urlObj.host === target) {
		// 	newType = 0;
		// 	newTarget = urlObj.host
		// } else if (urlObj.pathname !== '/') {
		// 	newType = 4;
		// 	newTarget = urlObj.href
		// } else if (urlObj.pathname === '/') {
		// 	newType = 3;
		// 	newTarget = urlObj.origin
		// }
		
		return {domain: target, domainTypeId: type};
	}
}
