import { Component, OnInit } from '@angular/core';
import {AccountFacade} from "../../account.facade";
import {User, UserSocialAccount} from "../../../core/models/user.model";
import {transformHttpError} from "../../../core/utils/utils";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../components/confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-google-connection',
  templateUrl: './google-connection.component.html',
  styleUrls: ['./google-connection.component.scss']
})
export class GoogleConnectionComponent implements OnInit {
  
  userSocialData!: UserSocialAccount | null;
  
  loading!: boolean;
  
  projectLoading: boolean = false;
  
  projects!: string[];
  constructor(private facade: AccountFacade, private _snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.facade.getSocialAccount().subscribe(data => {
      if (data) {
        this.userSocialData = data;
        if (data) {
          this.loadProjects()
        }
      }
    })
  }
  
  revokeAccount() {
    this.dialog.open(ConfirmDialogComponent, {
      width: "400px",
      maxWidth: "100%",
      data: {
        title: "Are you sure to revoke account?"
      }
    }).afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.doRevoke()
      }
    })
    
  }
  
  doRevoke() {
    this.loading = true;
    this.facade.revokeAccount().subscribe(data => {
      this._snackBar.open('Social account was revoked', 'Close', {duration: 2000, panelClass: ['snack-bar-success']});
      this.userSocialData = null;
      this.loading = false;
    }, error => {
      if (error.error.errors) {
        this._snackBar.open(transformHttpError(error.error.errors), 'Close', {duration: 2000, panelClass: ['snack-bar-error']})
      } else {
        this._snackBar.open(error.error.message, 'Close', {duration: 2000, panelClass: ['snack-bar-error']})
      }
      this.userSocialData = null;
      this.loading = false;
    });
  }
  
  loadProjects(type = 'basic') {
    if (this.projectLoading) {
      return;
    }
    this.projectLoading = true;
    this.facade.getGoogleDomains(type).subscribe(domains => {
      this.projects = domains;
      this.projectLoading = false;
    }, error => {}, () => {
      this.projectLoading = false;
    })
  }
  
  connectAccount() {
    this.goToThirdParty('google');
  }
  
  goToThirdParty(part: string) {
    this.loading = true;
    this.facade.goToThirdParty(part).subscribe((url) => {
      this.loading = false;
      window.location.href = url;
    })
  }

}
